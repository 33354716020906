import React, {useState, useEffect} from 'react';
import { dataClientes } from "../../services/Client"
import { alerta, formatearMoneda } from "../../services/utilities";
import Swal  from 'sweetalert2';
import { actualizar_cliente, tipo_activo, municipios, bancos } from "../../services/Client"
import { useNavigate } from 'react-router-dom';
import { cerrarModal} from "../../services/utilities"

function ViweClient(props){

    const [loading, setLoading] = useState('')
    const [loadingform, setLoadingForm] = useState('d-none')
    const [cliente, setCliente] = useState({});
    const [lista_municipios, setMunicipios] = useState([]);
    const [lista_tipo_activo, setListaTipoActivo] = useState([]);
    const [lista_bancos, setBancos] = useState([]);
    const navigate = useNavigate();

    useEffect( () =>{

        setLoadingForm('d-none')
        setLoading('')
        
        var collapse = document.getElementsByClassName('collapse')
        for(var i = 1; i < collapse.length; i++ ){collapse[i].classList.remove('show')}

        if(props.idCliente){
            dataClientes(props.idCliente).then(response => {
                if(response.status === 200){
                    setCliente(response.data.data[0])
                }else{
                    alerta('Error al cargar la lista de clientes', response.message)
                }
            }).catch(error =>{
                setLoading('d-none')
                alerta('Error al cargar la lista de clientes', error.message)
            }). finally(
                setTimeout(() => {
                    setLoading('d-none')
                    setLoadingForm('')
                  }, 500)
            )
        }
        

        municipios().then(response => {
            if(response.status === 200){
                setMunicipios(response.data.data);
            }else{
                alerta('Error al cargar la lista de municipios')
            }
        })

        tipo_activo().then(response => {
            if(response.status === 200){
                setListaTipoActivo(response.data.data);
            }else{
                alerta('Error al cargar la lista de tipos de propiedades')
            }
        })

        bancos().then(response => {
            if(response.status === 200){
                setBancos(response.data.data);
            }else{
                alerta('Error al cargar la lista de bancos')
            }
        })
        
    },[props.idCliente])
 

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCliente({ ...cliente, [name]: value });
    };

    const handleFormatMoney = (event) => {
        /*formatearMoneda(event.target)
        const { name, value } = event.target;
        setCliente({ ...client, [name]: value });*/
    }


    const handleUpdateClient = () =>{

        Swal.fire({
            title: '¿ Esta seguro de guardar la información del prospecto ?',
            text: "Si esta seguro presione el boton OK de lo contrario Cancelar",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancelar'
        }).then((result) => {

            if (result.isConfirmed) {
                actualizar_cliente(cliente).then(response => {
                    if(response.status === 200 && response.data.status_message == 'OK'){
                        alerta('Prospecto editado de forma correcta','','success');
                        cerrarModal('ModalViweClient');
                    }else{                        
                        alerta(response.data.error_message)
                    }
                })
            }
        })
    }

    return(

        <React.Fragment>
            <div className="modal fade" id="ModalViweClient" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-body modal-body-viwe-client">
                            <div className={`d-flex align-items-center ${loading}`}>
                                <strong>Por favor espere...</strong>
                                <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                            </div>
                            <form className={`formDataViweclient ${loadingform}`}>
                                {cliente.estado_id === 1 ? <span className="badge text-bg-success fs-6">Activo</span> : <span className="badge text-bg-danger fs-6">Inactivo</span>}
                                <div className="pt-4 info-personal ">
                                    <h6 className='text-secondary' data-bs-toggle="collapse">
                                        <i className="bi bi-file-person text-secondary fs-5"></i>
                                        &nbsp;Información Personal
                                    </h6>                                   
                                    <div className='row mt-4'>
                                        <div className="col-md-2">
                                            <label className="form-label" htmlFor="estado_id"><b>Estado:</b></label>
                                            <select className="form-select form-select-sm" name="estado_id" value={cliente.estado_id} onChange={handleInputChange}>
                                                <option value="1">Activo</option>
                                                <option value="0">Inactivo</option>
                                                <option value="10">Eliminar</option>
                                            </select>
                                        </div>
                                        <div className="col-md-2">
                                            <label className="form-label" htmlFor="nombre">Nombres:</label>
                                            <input type="text" id="nombre" name="nombre" placeholder="Digite el nombre del cliente" className="form-control form-control-sm" value={cliente.nombre} onChange={handleInputChange} />
                                        </div>
                                        <div className="col-md-2">
                                            <label className="form-label" htmlFor="apellido">Apellidos:</label>
                                            <input type="text" id="apellido" name="apellido" placeholder="Digite los apellidos del cliente" className="form-control form-control-sm" value={cliente.apellido} onChange={handleInputChange} />
                                        </div>
                                        <div className="col-md-2">
                                            <label className="form-label" htmlFor="numero_documento"># Documento:</label>
                                            <input type="number" id="numero_documento" name="numero_documento" placeholder="Numero de documento" className="form-control form-control-sm" value={cliente.numero_documento} onChange={handleInputChange} />
                                        </div>
                                        <div className="col-md-2">
                                            <label className="form-label" htmlFor="tipo_documento">Tipo Doumento:</label>
                                            <select className="form-select form-select-sm" name="tipo_documento" value={cliente.tipo_documento} onChange={handleInputChange}>
                                                <option value="CC">Cedula</option>
                                                <option value="TI">Tarjeta de Identidad</option>
                                                <option value="P">Pasaporte</option>
                                                <option value="CE">Cedula Extrangeria</option>
                                            </select>
                                        </div>
                                        <div className="col-md-2">
                                            <label className="form-label" htmlFor="sexo">Sexo:</label>
                                            <select className="form-select form-select-sm" name="sexo" value={cliente.sexo} onChange={handleInputChange}>
                                                <option value="M">MASCULINO</option>
                                                <option value="F">FEMENINO</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        <div className="col-md-2">
                                            <label className="form-label" htmlFor="lugar_expedicion_documento">Lugar nacimiento:</label>
                                            <input type="text" id="lugar_expedicion_documento" name="lugar_expedicion_documento" placeholder="Ej: Bogota" className="form-control form-control-sm" value={cliente.lugar_expedicion_documento} onChange={handleInputChange} />
                                        </div> 
                                        <div className="col-md-2">
                                            <label className="form-label" htmlFor="profesion">Profesión:</label>
                                            <input type="text" id="profesion" name="profesion" placeholder="Ej: Abogado" className="form-control form-control-sm" value={cliente.profesion} onChange={handleInputChange} />
                                        </div>
                                        <div className="col-md-2">
                                            <label className="form-label" htmlFor="fecha_nacimiento">Fecha nacimiento:</label>
                                            <input type="date" id="fecha_nacimiento" name="fecha_nacimiento" placeholder="Digite los apellidos del cliente" className="form-control form-control-sm" value={cliente.fecha_nacimiento} onChange={handleInputChange} />
                                        </div>
                                        <div className="col-md-2">
                                            <label className="form-label" htmlFor="fecha_expedicion_documento">Fecha expedicion:</label>
                                            <input type="date" id="fecha_expedicion_documento" name="fecha_expedicion_documento" placeholder="Digite los apellidos del cliente" className="form-control form-control-sm" value={cliente.fecha_expedicion_documento}onChange={handleInputChange} />
                                        </div>
                                        <div className="col-md-2">
                                            <label className="form-label" htmlFor="lugar_expedicion_documento">Lugar expedicion:</label>
                                            <input type="text" id="lugar_expedicion_documento" name="lugar_expedicion_documento" placeholder="Ej: Bogota" className="form-control form-control-sm" value={cliente.lugar_expedicion_documento} onChange={handleInputChange} />
                                        </div>
                                        <div className="col-md-2">
                                            <label className="form-label" htmlFor="estado_civil">Estado Civil:</label>
                                            <select className="form-select form-select-sm" name="estado_civil" value={cliente.estado_civil} onChange={handleInputChange}>
                                                <option value="SOLTERO">SOLTERO</option>
                                                <option value="CASADO">CASADO</option>
                                                <option value="U LIBRE">U LIBRE</option>
                                            </select>
                                        </div>
                                    </div>                                    
                                </div>
                                <div className="mt-4 info-contacto">
                                    <h6 className='text-secondary'>
                                        <i className="bi bi-geo-alt-fill text-secondary fs-5"></i>
                                        &nbsp;Información de contacto
                                    </h6>
                                    
                                    <div className='row mt-4'>
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="telefono_1">Telefono:</label>
                                            <input type="number" id="telefono_1" name="telefono_1" placeholder="Telefono celular" className="form-control form-control-sm" maxLength="10" value={cliente.telefono_1} onChange={handleInputChange} />
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="telefono_2">Telefono 2:</label>
                                            <input type="number" id="telefono_2" name="telefono_2" placeholder="Telefono fijo" className="form-control form-control-sm" maxLength="10" value={cliente.telefono_2} onChange={handleInputChange} />
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="email">Correo electronico:</label>
                                            <input type="text" id="email" name="email" placeholder="ejemplo@dominio.com" className="form-control form-control-sm" value={cliente.email} onChange={handleInputChange} />
                                        </div> 
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="municipio_id">Ciudad de residencia: <span className='text-danger'>*</span></label>
                                            <select className="form-select form-select-sm" name="municipio_id" value={cliente.municipio_id} onChange={handleInputChange}>
                                                {lista_municipios.map((municipio, indice) => (
                                                    <option value={municipio.id_municipio} key={indice}>{municipio.nombre}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="direccion">Direccion:</label>
                                            <input type="text" id="direccion" name="direccion" placeholder="Calle 14 Cra 10 " className="form-control form-control-sm" value={cliente.direccion} onChange={handleInputChange} />
                                        </div> 
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="barrio">Barrio / Verdad / Localidad:</label>
                                            <input type="text" id="barrio" name="barrio" placeholder="Ej: Centro" className="form-control form-control-sm" value={cliente.barrio} onChange={handleInputChange} />
                                        </div> 
                                    </div>
                                    
                                </div>
                                <div className='pt-3 info-financiera'>
                                    <h6 className='text-secondary'>
                                        <i className="bi bi-cash-coin text-secondary fs-5"></i>
                                        &nbsp;Información Financiera
                                    </h6>                                
                                    <div className="row mt-4">
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="ingresos_fijos">Ingresos fijos</label>
                                            <input type="number" id="ingresos_fijos" name="ingresos_fijos" placeholder="$1.000.000" className="form-control form-control-sm"  value={cliente.ingresos_fijos} onChange={handleInputChange} onBlur={handleFormatMoney} />
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="otros_ingresos">Otros ingresos</label>
                                            <input type="number" id="otros_ingresos" name="otros_ingresos" placeholder="$1.000.000" className="form-control form-control-sm"  value={cliente.otros_ingresos} onChange={handleInputChange} onBlur={handleFormatMoney} />
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="gastos_fijos">Gastos fijos</label>
                                            <input type="number" id="gastos_fijos" name="gastos_fijos" placeholder="$1.000.000" className="form-control form-control-sm"  value={cliente.gastos_fijos} onChange={handleInputChange} onBlur={handleFormatMoney} />
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="deudas">Deudas u obligaciones</label>
                                            <input type="number" id="deudas" name="deudas" placeholder="$1.000.000" className="form-control form-control-sm"  value={cliente.deudas} onChange={handleInputChange} onBlur={handleFormatMoney} />
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="tipo_cuenta">Tipo cuenta bancaria</label>
                                            <select className="form-select form-select-sm" name="tipo_cuenta" value={cliente.tipo_cuenta} onChange={handleInputChange} >
                                                <option value="AHORROS">AHORROS</option>
                                                <option value="CORRIENTE">CORRIENTE</option>
                                                <option value="CONVENIO">CONVENIO</option>
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="numero_cuenta"># Cuenta Bancaria</label>
                                            <input type="text" id="numero_cuenta" name="numero_cuenta" placeholder="00-00000-000" className="form-control form-control-sm"  value={cliente.numero_cuenta} onChange={handleInputChange} />
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="banco_id">Banco</label>
                                            <select className="form-select form-select-sm" name="banco_id" value={cliente.banco_id} onChange={handleInputChange}>
                                                {lista_bancos.map((banco, indice) => (
                                                    <option value={banco.id_banco} key={indice}>{banco.nombre}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>                                    
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className='btn btn-secondary' data-bs-dismiss="modal">Cancelar</button>
                            <button type="button" className='btn btn-primary' onClick={handleUpdateClient}>Guardar Prospecto</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ViweClient