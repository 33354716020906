import React,{ useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { setIdCredito } from '../../actions';
import { get_token } from "../../services/Credito"
import { alerta } from "../../services/utilities"

function ModalGenerarToken(props) {

    const navigate = useNavigate();
    const [token, setToken] = useState('');
    const [spiner, setSpiner] = useState(false);
    const [numero_credito, setNumeroCredito] = useState(false);

    useEffect(() => {

        if(props.idCredito){
            handleConsultarCredito()
            setNumeroCredito(props.idCredito)
        }

    }, [props.idCredito]);

    const handleConsultarCredito = async () => {

        setSpiner(true);

        try {
            await get_token(props.idCredito).then(response => {
                if(response.status === 200 && response.data.status_message == 'OK'){
                    setToken(response.data.token);
                    props.setIdCredito(0);
                }else{
                    alerta('No fue posible generar el token',response.data.status_message);
                }
            });
         }catch (error) {
            const errorMessage = error.message || 'Error desconocido';
            alerta('Error al cargar la información de los usuarios', `Por favor intente nuevamente. Detalles del error: ${errorMessage}`);
        }

        setSpiner(false);
    }

    return(

        <React.Fragment>
           <div className="modal fade" id="modalGenerarToken" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-header bg-warning">
                            <h5 className='m-0 text-white'><b>Token para el crédito</b> <span className="badge text-bg-dark"><i className="bi bi-upc"></i> {numero_credito}</span></h5>
                        </div>
                        <div className="modal-body">
                            <div className='content-card-token'>
                                <p className='m-0'>{token}</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary btn-lg" data-bs-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapSateToProps = state => {

    return {
        idCredito: state.idCredito
    }
}

const mapDispatchToProps  = {
    setIdCredito
};

export default connect(mapSateToProps, mapDispatchToProps)(ModalGenerarToken)
