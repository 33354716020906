import React,{ useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { generar_codigo_activacion, codigos_activos} from "../../services/Ajustes"
import { connect } from 'react-redux';
import { setIdCredito } from '../../actions';
import { toast, alerta, abrirModal, cerrarModal, formatearMonedaValue, formatDate } from "../../services/utilities"

function ModalCodigoActivacionProspectos(props) {

    useEffect(() => {
        handleGetCodigosActivos();
    }, []);

    const [spiner, setSpiner] = useState(true);
    const [data, setData] = useState([]);
    const [codigo, setCodigo] = useState('');

    const handleGenerarCodigo = async () => {
        
        setSpiner(true);

        try {
            await generar_codigo_activacion().then(response => {
                if(response.status === 200){
                    setCodigo(response.data.codigo);
                }else{
                    toast('Error al cargar la lista de bancos','Por favor intente nuevamente')
                }
            });
         }catch (error) {
            const errorMessage = error.message || 'Error desconocido';
            toast('Error al cargar la lista de bancos', `Por favor intente nuevamente. Detalles del error: ${errorMessage}`);
        }

        setSpiner(false);
    }

    const handleGetCodigosActivos = async () => {
        
        setSpiner(true);

        try {
            await codigos_activos().then(response => {
                if(response.status === 200){
                    setData(response.data.codigos);
                }else{
                    toast('Error al cargar la lista de bancos','Por favor intente nuevamente')
                }
            });
         }catch (error) {
            const errorMessage = error.message || 'Error desconocido';
            toast('Error al cargar la lista de bancos', `Por favor intente nuevamente. Detalles del error: ${errorMessage}`);
        }

        setSpiner(false);
    }

    return(

        <React.Fragment>
            <div className="modal fade" id="ModalCodigoActivacionProspectos" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
                    <div className="modal-content">
                        <div className="modal-header bg-success">
                        <h5 className="m-0 text-white">Código de activación prospecto</h5>                         
                        </div>
                        <div className="modal-body">
                        {spiner ?
                            <div className="d-flex align-items-center mb-3">
                                <strong role="status" className='text-secondary'>Cargando Informacion...</strong>
                                <div className="spinner-border ms-auto text-secondary" aria-hidden="true"></div>
                            </div>
                        :''}
                        <div className="d-flex flex-column justify-content-center align-items-center text-center text-secondary my-4">
                            <i className="bi bi-qr-code fs-1"></i>
                            <br></br>
                            <h1 className='text-success' style={{'letter-spacing': '6px'}}><b>{codigo}</b></h1>
                        </div>
                        <p>Últimos 5 codigos disponibles</p>
                        <table className="table table-hover table-sm text-center">
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Fecha creación</th>
                                    <th>Fecha vencimiento</th>
                                </tr>
                            </thead>
                            <tbody>
                        {Array.isArray(data) ? data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.codigo}</td>
                                <td>{item.fecha_registro}</td>
                                <td>{item.fecha_vencimiento}</td>
                            </tr>
                        )) : 
                            <p className='text-center'>No hay códigos activos, por favor genere un nuevo código.</p>
                        }
                            </tbody>
                        </table>                            
                        </div>
                        <div className="modal-footer">
                            <button 
                                className="btn btn-secondary btn-lg" 
                                data-bs-dismiss="modal"
                                >Cancelar
                            </button>
                            <button 
                                className="btn btn-success btn-lg"
                                disabled={spiner==true}
                                onClick={handleGenerarCodigo}
                                >Generar nuevo código
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapDispatchToProps  = {
    setIdCredito
};

export default connect(null, mapDispatchToProps)(ModalCodigoActivacionProspectos)
