import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { setCliente } from '../../actions'
import  avatar_gril from  "../../assetss/img/avatar_gril.png"
import  avatar_men from  "../../assetss/img/avatar_men.png"
import { toast } from "../../services/utilities";
import { dataClientes } from "../../services/Client"

function ModalListaClientes(props) {

    useEffect(() => {

        dataClientes().then(response => {
            if(response.status === 200){
                setDataCliente(response.data.data)
            }else{
                toast('Error al cargar la lista de clientes', response.message)
            }
        }).catch(error =>{
            toast('Error al cargar la lista de clientes', error.message)
        }). finally(
            setTimeout(() => {
                
              }, 500)
        )
    }, []);

    const [filter, setFilter] = useState('');
    const [dataCliente, setDataCliente] = useState([]);

    const filteredData = dataCliente.filter((item) => {
        const nombre = item.nombre.toLowerCase().includes(filter.toLowerCase());
        const apellido = item.apellido.toLowerCase().includes(filter.toLowerCase());
        const numero_documento = item.numero_documento.toLowerCase().includes(filter.toLowerCase());
        return nombre || apellido || numero_documento;
    });

    return(
        <React.Fragment>
            <div className="modal fade" id="modalClientes" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered  modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">                            
                            <div className="input-group" style={{width:'40%'}}>
                                <input type="text" className="form-control form-control-sm" placeholder="Buscar cliente..." aria-label="Buscar" aria-describedby="basic-addon1" value={filter}
                                 onChange={(e) => setFilter(e.target.value)} />
                            </div>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">

                            <table className='table table-hover' style={{'fontSize':'14px'}}>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Cliente</th>
                                        <th>Documento</th>
                                        <th>Estado</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.map((cliente, index) => (
                                        <tr key={index}>
                                            <td>{cliente.id_cliente}</td>
                                            <td>
                                                <img width="20" className="rounded-circle mx-2" src={cliente.sexo == 'M'? avatar_men : avatar_gril } alt="" />
                                                {cliente.nombre} {cliente.apellido}
                                            </td>
                                            <td>{cliente.tipo_documento} {cliente.numero_documento}</td>                                            
                                            <td>{cliente.estado_id === 1 ? <span className="badge text-bg-success">Activo</span> : <span className="badge text-bg-danger">Inactivo</span>}
                                            </td>
                                            <td>
                                                {cliente.estado_id === 1 ?
                                                    <a  href="#" 
                                                        data-bs-toggle="modal" 
                                                        data-bs-target="#modalCredito" 
                                                        className="link-primary icon-link-hover " 
                                                        title="Ver informacion del cliente"
                                                        onClick={() =>{
                                                            props.setCliente(cliente);;
                                                        }}
                                                    >
                                                        <i className="bi bi-clipboard-check"></i>
                                                    </a>
                                                :''}
                                            </td>
                                        </tr>
                                    ))}                                  
                                </tbody>
                            </table>
            
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary btn-lg" data-bs-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapDispatchToProps  = {
    setCliente
};

export default connect(null, mapDispatchToProps)(ModalListaClientes)