import React,{ useState, useEffect } from 'react';
import { tasa_interes_empresa, tasas_interes_empresa, editar_tasa_interes_empresa } from "../../services/Ajustes"
import { connect } from 'react-redux';
import { setIdCredito } from '../../actions';
import { toast, alerta, cerrarModal } from "../../services/utilities"

function ModalConfigTasasApp(props) {

    useEffect(() => {
        handleConsultarTasas();
    }, []);

    const [spiner, setSpiner] = useState(false);
    const [medios, setMedios] = useState([]);

    const [form, setForm] = useState({
        "id_empresa_tasa_tipo_credito": null,
        "tasa": "",
        "tipo": "",
        "descripcion": "",
        "empresa_id": null
    });

    const handleInputChange = (event) => {
        const { name, value, type } = event.target;
        setForm({ ...form, [name]: value });
    };

    const handleConsultarTasas = async () => {
        
        try {
            await tasas_interes_empresa().then(response => {
                if(response.status === 200){
                    setMedios(response.data.data);
                }else{
                    toast('Error al cargar la lista de medios de pagos','Por favor intente nuevamente')
                }
            });
         }catch (error) {
            const errorMessage = error.message || 'Error desconocido';
            toast('Error al cargar la lista de bancos', `Por favor intente nuevamente. Detalles del error: ${errorMessage}`);
        }
    }


    const handleConsultarTasa = async (id_empresa_tasa_tipo_credito) => {
        
        try {
            await tasa_interes_empresa(id_empresa_tasa_tipo_credito).then(response => {
                if(response.status === 200){
                    setForm(response.data.data);
                }else{
                    toast('Error al cargar la lista de medios de pagos','Por favor intente nuevamente')
                }
            });
         }catch (error) {
            const errorMessage = error.message || 'Error desconocido';
            toast('Error al cargar la lista de bancos', `Por favor intente nuevamente. Detalles del error: ${errorMessage}`);
        }
    }

    const handleEditarTasaInteres = async () => {
        
        try {
            await editar_tasa_interes_empresa(form).then(response => {
                
                if(response.status === 200 && response.data.status_message == 'OK'){

                    handleConsultarTasas();

                    setForm({
                        "id_empresa_tasa_tipo_credito": null,
                        "tasa": "",
                        "tipo": "",
                        "descripcion": "",
                        "empresa_id": null
                    });

                    alerta('Tasa de interes editada de forma correcta','','success');
                    cerrarModal('ModalConfigTasasApp');

                }else{
                    alerta(response.data.status_message, response.data.error_message);
                }

            });
         }catch (error) {
            const errorMessage = error.message || 'Error desconocido';
            toast('Error al editar la tasa de ineteres', `Por favor intente nuevamente. Detalles del error: ${errorMessage}`);
        }
    }
   
    return(

        <React.Fragment>
           <div className="modal fade" id="ModalConfigTasasApp" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header bg-header-modal">
                            <h5 id="staticBackdropLabel" className='m-0'>Configuración de tasas para la app</h5>
                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='row'>
                                        <div className='col-md-12 mt-3'>
                                            <label className="form-label" >Tipo</label>
                                            <div className="input-group">
                                                <input 
                                                    type="text" 
                                                    className="form-control form-control-md" 
                                                    id="tipo" 
                                                    name="tipo" 
                                                    value={form.tipo}
                                                    disabled={true}
                                                    onChange={handleInputChange} 
                                                />
                                            </div>
                                        </div>                                      
                                        <div className='col-md-12 mt-3'>
                                            <label className="form-label" >Descripción</label>
                                            <div className="input-group">
                                                <input 
                                                    type="text" 
                                                    className="form-control form-control-md" 
                                                    id="descripcion" 
                                                    name="descripcion" 
                                                    value={form.descripcion}
                                                    disabled={true}
                                                    onChange={handleInputChange} 
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-12 mt-3'>
                                            <label className="form-label" >Tasa de interes<span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <input 
                                                    type="text" 
                                                    className="form-control form-control-md" 
                                                    id="tasa" 
                                                    name="tasa" 
                                                    value={form.tasa}
                                                    disabled={false}
                                                    onChange={handleInputChange} 
                                                />
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <table className='table table-hover' style={{fontSize:'12px'}}>
                                        <thead>
                                            <tr>
                                                <th>Tipo</th>
                                                <th>Tasa</th>                                                
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {Array.isArray(medios) ? medios.map((item, index) => (
                                            <tr key={index} className='align-middle'>
                                                <td>{item.descripcion}</td>
                                                <td>{item.tasa}</td>                                            
                                                <td><button type="button" className="btn btn-warning btn-sm" onClick={() => handleConsultarTasa(item.id_empresa_tasa_tipo_credito)}>Editar</button></td>
                                            </tr>
                                        )) : 
                                            <p className='text-center'>No hay tassas configuradas para la empresa</p>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                            <button 
                                className="btn btn-success"
                                disabled={form.empresa_id == null}
                                onClick={handleEditarTasaInteres}
                                
                                >Guardar cambios
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapDispatchToProps  = {
    setIdCredito
};

export default connect(null, mapDispatchToProps)(ModalConfigTasasApp)
