import React,{ useState } from 'react';
import { connect } from 'react-redux';
import { cambiar_password } from "../../services/Login"
import { toast, alerta, cerrarModal } from "../../services/utilities"
import Swal  from 'sweetalert2';

function CambiarContrasena(props) {

    const [form, setForm] = useState({
        "id_usuario" : props.usuario.id_usuario,
        "password_actual" :'',
        "password_uno" :'',
        "password_dos" :''
    });

    const handleInputChange = (event) => {

        const { name, value, type } = event.target;
        setForm({ ...form, [name]: value });
    };

    const handleCambiarContrasena = async () => {

        if(form.password_actual.length < 2){
            toast('El campo contraseña actual es requerido','Por favor, ingrese su contraseña actual.',true);
            return;
        }

        if(form.password_uno.length < 4){
            toast('La nueva contraseña ingresada no es valida','Debe contener al menos 4 caracteres.',true);
            return;
        }

        if(form.password_uno != form.password_dos){
            toast('Las contraseñas no son iguales','Por favor, valide nuevamente.',true);
            return;
        }

        await cambiar_password(form).then(response => {

            console.log(response)

            if(response.status === 200 && response.data.status_message == 'OK'){

                cerrarModal('modalCambioDeContrasena')

                Swal.fire({
                    title: "Cambio de contraseña exitoso",
                    text: "Presione 'Aceptar' para ir volver al inicio.",
                    icon: "success",
                    confirmButtonColor: '#198754',
                    confirmButtonText: 'Aceptar',
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.href = '/inicio';
                    }
                });

            }else{
                alerta('No fue posible cambiar la contraseña',response.data.status_message);
            }
        });
    }

    return(

        <React.Fragment>
           <div className="modal fade" id="modalCambioDeContrasena" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
                    <div className="modal-content">                    
                        <div className="modal-header bg-header-modal">
                            <h5 className='m-0'>Cambiar contraseña</h5>
                        </div>
                        <div className="modal-body">
                            <div className='row'>

                                <div className='col-md-12'>
                                    <label className="form-label" >Contraseña actual: <span className='text-danger'>*</span></label>
                                    <input 
                                        type="password" 
                                        className="form-control form-control-lg" 
                                        id="password_actual" 
                                        name="password_actual" 
                                        value={form.password_actual}
                                        style={{fontSize:'32px'}}
                                        onChange={handleInputChange} 
                                    />
                                </div>

                                <div className='col-md-12 mt-4'>
                                    <label className="form-label" >Nueva constraseña: <span className='text-danger'>*</span></label>
                                    <input 
                                        type="password" 
                                        className="form-control form-control-lg" 
                                        id="password_uno" 
                                        name="password_uno" 
                                        value={form.password_uno}
                                        style={{fontSize:'32px'}}
                                        onChange={handleInputChange} 
                                    />
                                </div>

                                <div className='col-md-12 mt-4'>
                                    <label className="form-label" >Confirmé la contraseña: <span className='text-danger'>*</span></label>
                                    <input 
                                        type="password" 
                                        className="form-control form-control-lg" 
                                        id="password_dos" 
                                        name="password_dos" 
                                        value={form.password_dos}
                                        style={{fontSize:'32px'}}
                                        onChange={handleInputChange}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button 
                                className="btn btn-secondary btn-lg" 
                                data-bs-target="#modalCambioDeContrasena" 
                                data-bs-toggle="modal"
                                >Cancelar
                            </button>
                            <button 
                                className="btn btn-success btn-lg"    
                                onClick={handleCambiarContrasena}                           
                                >Cambiar contraseña
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapSateToProps = state => {

    return {
        usuario: state.usuario
    }
}

export default connect(mapSateToProps, null)(CambiarContrasena)
