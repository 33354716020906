import React,{ useState } from 'react';
import { toast, alerta, formatearMonedaValue, formatDate } from "../../services/utilities";
import { extracto } from "../../services/Reportes"
function Extracto() {

    const [spiner, setSpiner] = useState(false);
    const [tabla, setTabla] = useState([]);

    const [filtro, setFiltro] = useState({
        "tipo_saldo":"",
        "fecha_ini": formatDate(new Date()),
        "fecha_fin": formatDate(new Date())
    });

    const handleInputChange = (event) => {

        const { name, value } = event.target;
        setFiltro({ ...filtro, [name]: value });
        
    };

    const handleConsultarExtracto = async () => {

        if(filtro.tipo_saldo.length === 0){
            toast('Debe seleccionar un tipo de saldo','',true);
            return;
        }
   
        if(filtro.fecha_ini.length === 0){
            toast('La fecha inicial no es valida','',true);
            return;
        }

        if(filtro.fecha_fin.length === 0){
            toast('La fecha final no es valida','',true);
            return;
        }
    
        setSpiner(true)

        try {
            await extracto(filtro.fecha_ini, filtro.fecha_fin, filtro.tipo_saldo).then(response => {
                if(response.status === 200){
                    setTabla(response.data.data);
                }else{
                    alerta('Error al cargar la lista de creditos','Por favor intente nuevamente')
                }
            });
         }catch (error) {
            const errorMessage = error.message || 'Error desconocido';
            alerta('Error al cargar la lista de créditos', `Por favor intente nuevamente. Detalles del error: ${errorMessage}`);
        }

        setSpiner(false)

    }

    return(

        <React.Fragment>
            <div className='container-modulos'>
                <div className='row'>

                    <div className='col-md-2'>
                        <label className="form-label" htmlFor="tipo_saldo">Tipo de saldo</label>
                        <select className="form-select form-select-sm" name="tipo_saldo" value={filtro.tipo_saldo} onChange={handleInputChange}>
                            <option value="">-Seleccione-</option>
                            <option value="1">Disponible</option>
                            <option value="2">Prestado</option>
                            <option value="3">Intereses</option>
                        </select>
                    </div>
                    
                    <div className='col-md-2'>
                        <label className="mb-1">Desde:</label>
                        <div className="input-group">
                            <input 
                                type="date" 
                                className="form-control" 
                                id="fecha_ini" 
                                name="fecha_ini" 
                                value={filtro.fecha_ini}
                                onChange={handleInputChange} 
                            />
                        </div>
                    </div>

                    <div className='col-md-2'>
                        <label className="mb-1">Hasta:</label>
                        <div className="input-group">
                            <input 
                                type="date" 
                                className="form-control" 
                                id="fecha_fin" 
                                name="fecha_fin" 
                                value={filtro.fecha_fin}
                                onChange={handleInputChange} 
                            />
                        </div>
                    </div>                           
                    
                    <div className="col-md-1">
                        <label className='mb-2'>&emsp;</label>
                        <div className="input-group">
                            <button 
                                type="button" 
                                className="btn btn-outline-primary btn-sm"
                                onClick={handleConsultarExtracto}
                                disabled={spiner}
                            >
                                Buscar
                            </button>
                        </div>
                    </div>

                </div>
                <br></br>
                {spiner ?
                <div className="d-flex align-items-center mb-4">
                    <strong role="status" className='text-secondary'>Cargando Informacion...</strong>
                    <div className="spinner-border ms-auto text-secondary" aria-hidden="true"></div>
                </div>:''}
                <div className="table-responsive">
                    {tabla.length > 0 ?
                        <table className="table table-hover table-sm text-start" style={{fontSize:'11px'}}>
                            <thead>
                                <tr>
                                    <th>Transaccion</th>
                                    <th>Credito</th>
                                    <th>Fecha</th>
                                    <th>Hora</th>
                                    <th>Tipo transaccion</th>
                                    <th className='table-primary'>Saldo inicial</th>
                                    <th className='table-primary'>Valor</th>
                                    <th className='table-primary'>Saldo final</th>
                                    <th>Observación</th>
                                    <th>Usuario</th>
                                </tr>
                            </thead>
                            <tbody>
                            {Array.isArray(tabla) ? tabla.map((item, index) => (
                                <tr key={index} className='align-middle'>
                                    <td>{item.id_transaccion}</td>
                                    <td>{item.id_credito}</td>
                                    <td>{item.fecha}</td>
                                    <td>{item.hora}</td>
                                    <td>{item.tipo_transaccion}</td>
                                    <td className='table-primary'><b>${formatearMonedaValue(item.valor_ini)}</b></td>
                                    <td className={item.valor < 0 ? 'text-danger table-primary' : 'text-success table-primary'}><b>${formatearMonedaValue(item.valor)}</b></td>
                                    <td className='table-primary'><b>${formatearMonedaValue(item.valor_fin)}</b></td>
                                    <td style={{fontSize:'10px'}}>{item.observacion}</td>
                                    <td>{item.nickname}</td>
                                </tr>
                            )) : <tr></tr>}
                            </tbody>
                        </table>
                    :
                        <div className='text-center text-secondary my-4 align-items-center'>
                            <h3>No se encontraron resultados.</h3>
                            <p>Por favor, ajuste los filtros de búsqueda e intente nuevamente.</p>
                        </div>
                    }
                </div>
            </div>
        </React.Fragment>
    );
}

export default Extracto