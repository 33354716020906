import React, {useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setSaldosEmpresa, setCredito } from '../../actions'
import Swal  from 'sweetalert2';
import { alerta, toast } from "../../services/utilities";
import { cerrarModal, validarFormatoFecha } from "../../services/utilities"
import { crear_credito } from "../../services/Credito"

function ModalIniciarCredito(props) {

    useEffect(() => {

        props.setCredito({ 
            ...props.credito, 
            cliente_id: props.cliente.id_cliente
        });

        setCuotaOriginal(props.credito.cuota_mensual);
        
    }, [props.cliente.id_cliente]);

    const [spiner, setSpiner] = useState(false);
    const [vista_formulario, setVistaFormulario] = useState('credito');
    const [fecha_fin, setFechaFin] = useState('');
    const [cuota_original, setCuotaOriginal] = useState(0);
    
    const [vehiculo, setVehiculo] = useState({
        'placa':'',
        'marca':'',
        'linea':'',
        'modelo':'',
        'color':'',
        'motor':'',
        'chasis':'',
        'serie':'',
        'cilindraje':'',
        'capacidad':'',
        'kilometraje':'',
        'valor_vehiculo':'',
        'observacion':''
    });

    const [propiedad, setPropiedad] = useState({
        'tipo_propiedad':'',
        'ubicacion':'',
        'direccion':'',
        'tamano_propiedad':'',
        'numero_escritura':'',
        'registro_catastral':'',
        'ano_construccion':'',
        'valor_propiedad':''
    });

    const [letra, setLetra] = useState({
        'tipo_documento':'',
        'fecha_emision':'',
        'fecha_vencimiento':'',
        'nombre_emisor':'',
        'nombre_beneficiario':'',
        'valor':'',
        'observacion':''
    });

    const handleInputVehiculoChange = (event) => {
        const { name, value } = event.target;
        if(name === 'valor_vehiculo'){
            var inputValue = value.replace(/\D/g, '');
            const formattedNumber = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            setVehiculo({ ...vehiculo, [name]: formattedNumber });
        }else{
            setVehiculo({ ...vehiculo, [name]: value });
        }
    };

    const handleInputPropiedadChange = (event) => {
        const { name, value } = event.target;
        if(name === 'valor_propiedad'){
            var inputValue = value.replace(/\D/g, '');
            const formattedNumber = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            setPropiedad({ ...propiedad, [name]: formattedNumber });
        }else{
            setPropiedad({ ...propiedad, [name]: value });
        }
    };

    const handleInputLetraChange = (event) => {
        const { name, value } = event.target;
        if(name === 'valor'){
            var inputValue = value.replace(/\D/g, '');
            const formattedNumber = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            setLetra({ ...letra, [name]: formattedNumber });
        }else{
            setLetra({ ...letra, [name]: value });
        }
    };

    const handleGuardarVehiculo = () => {

        if(vehiculo.marca == ''){
            toast('* Campos requeridos','Debe ingresar la marca del vehiculo', true);
            return;
        }else if(vehiculo.linea == ''){
            toast('* Campos requeridos','Debe ingresar la linea del vehiculo', true);
            return;

        }else if(vehiculo.valor_vehiculo == '' || vehiculo.valor_vehiculo.length < 1 || vehiculo.valor_vehiculo == 0){
            toast('* Campos requeridos','El valor del vehíuclo debe ser mayor a $0', true);
            return;
        }

        var x = props.credito.vehiculos;
        x.push(vehiculo);

        props.setCredito({ 
            ...props.credito, 
            vehiculos: [...props.credito.vehiculos, x]
        });

        setVehiculo({
            'placa':'',
            'marca':'',
            'linea':'',
            'modelo':'',
            'color':'',
            'motor':'',
            'chasis':'',
            'serie':'',
            'cilindraje':'',
            'capacidad':'',
            'kilometraje':'',
            'valor_vehiculo':'',
            'observacion':''
        });

        props.setCredito({ ...props.credito, ['tipo_garantia']: '' });
        setVistaFormulario('credito');
        toast('¡Muy bien!','Vehículo agregado con exito', false, 'success');
    }

    const handleGuardarPropiedad = () => {

        if(propiedad.tipo_propiedad == ''){
            toast('* Campos requeridos','Debe ingresar el tipo de propiedad', true);
            return;
        }else if(propiedad.ubicacion == ''){
            toast('* Campos requeridos','Debe ingresar la ubicación', true);
            return;

        }else if(propiedad.valor_propiedad == '' || propiedad.valor_propiedad.length < 1 || propiedad.valor_propiedad == 0){
            toast('* Campos requeridos','El valor de la propiedad debe ser mayor a $0', true);
            return;
        }

        var x = props.credito.propiedades;
        x.push(propiedad);

        props.setCredito({ 
            ...props.credito, 
            propiedades: [...props.credito.propiedades, x]
        });

        setPropiedad({
            'tipo_propiedad':'',
            'ubicacion':'',
            'direccion':'',
            'tamano_propiedad':'',
            'numero_escritura':'',
            'registro_catastral':'',
            'ano_construccion':'',
            'valor_propiedad':''
        });

        props.setCredito({ ...props.credito, ['tipo_garantia']: '' });
        setVistaFormulario('credito');
        toast('¡Muy bien!','Propiedad agregada con exito', false, 'success');
    }

    const handleGuardarLetra = () => {

        if(letra.tipo_documento == ''){
            toast('* Campos requeridos','Debe ingresar el tipo de documento', true);
            return;
        }else if(letra.fecha_emision == ''){
            toast('* Campos requeridos','Debe ingresar la fecha de emision', true);
            return;

        }else if(letra.valor == '' || letra.valor.length < 1 || letra.valor == 0){
            toast('* Campos requeridos','El valor del documento debe ser mayor a $0', true);
            return;
        }

        var x = props.credito.letras;
        x.push(letra);

        props.setCredito({ 
            ...props.credito, 
            letras: [...props.credito.letras, x]
        });

        setLetra({
            'tipo_documento':'',
            'fecha_emision':'',
            'fecha_vencimiento':'',
            'nombre_emisor':'',
            'nombre_beneficiario':'',
            'valor':'',
            'observacion':''
        });

        props.setCredito({ ...props.credito, ['tipo_garantia']: '' });
        setVistaFormulario('credito');
        toast('¡Muy bien!','Documento agregada con exito', false, 'success');
    }

    const handleInputCreditoChange = (event) => {

        const { name, value } = event.target;

        if(name === 'fecha_inicio'){

            let fecha   = new Date(value);
            let dia     = fecha.getUTCDate();
            fecha.setMonth(fecha.getMonth() + parseInt(props.credito.cantidad_cuotas));
            let fecha_final = new Date(fecha.getFullYear(), fecha.getMonth(), dia);
            setFechaFin(fecha_final.toISOString().split('T')[0]);

            props.setCredito({ 
                ...props.credito, 
                fecha_inicio: value,
                fecha_fin: fecha_final.toISOString().split('T')[0]
            });

        }else if(name === 'saldo_actual' || name === 'cuota_mensual'){

            var inputValue = value.replace(/\D/g, '');
            const formattedNumber = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            props.setCredito({ ...props.credito, [name]: formattedNumber });

        }else if(name === 'credito_nuevo'){
            
            props.setCredito({ 
                ...props.credito, 
                credito_nuevo: value,
                cuota_mensual: cuota_original
            });
       
        }else{
            props.setCredito({ ...props.credito, [name]: value });
        }

        if(name == 'tipo_garantia'){
            handleAbrirModalGarantiaCredito(value);
        }     
    };

    const handleConfirmarCredito = () => {
       
        if(!validarFormatoFecha(props.credito.fecha_inicio)){
            toast('* Campos requeridos','Seleccione una fecha de inicio valida', true);
            return;
        }

        if(!validarFormatoFecha(props.credito.fecha_ultimo_pago)){
            toast('* Campos requeridos','Seleccione una fecha de último pago valida', true);
            return;
        }

        if(props.credito.observacion === undefined || props.credito.observacion.length < 1){
            toast('* Campos requeridos','Debe de digitar una observacion para el credito', true);
            return;
        }

        if(props.credito.credito_nuevo == '0' && props.credito.numero_cuota_ajuste.length < 1){
            toast('* Campos requeridos','El campo "Ultima cuota pagada" no puede estar vacio', true);
            return;
        }

        if(props.credito.credito_nuevo == '0' && props.credito.saldo_actual.length < 1){
            toast('* Campos requeridos','Debe indicar el saldo actual del credito', true);
            return;
        }

        Swal.fire({
            html: `
                    <p><b>Por favor, verifique la información del crédito.</b></p>
                    <hr></hr>
                    <div class='d-flex align-items-center justify-content-between  m-0'>
                        <h6 class="card-subtitle mb-2 text-body-secondary">Cliente</h6>
                        <p class='m-0'><b>${props.cliente.nombre +' '+ props.cliente.apellido}</b></p>
                    </div>
                    <div class='d-flex align-items-center justify-content-between  m-0'>
                        <h6 class="card-subtitle mb-2 text-body-secondary">Total de cuotas</h6>
                        <p class='m-0 text-danger'><b>${props.credito.cantidad_cuotas}</b></p>
                    </div>
                    <div class='d-flex align-items-center justify-content-between  m-0'>
                        <h6 class="card-subtitle mb-2 text-body-secondary">Intereses</h6>
                        <p class='m-0 text-danger'><b>${props.credito.interes}%</b></p>
                    </div>                  
                    <div class='d-flex align-items-center justify-content-between m-0'>
                        <h6 class="card-subtitle mb-2 text-body-secondary">Valor cuota</h6>
                        <p class='m-0 text-success'><b>$${props.credito.cuota_mensual}</b></p>
                    </div>                    
                    <div class='d-flex align-items-center justify-content-between  m-0'>
                        <h6 class="card-subtitle mb-2 text-body-secondary">Valor del credito</h6>
                        <p class='m-0 text-success'><b>$${props.credito.valor}</b></p>
                    </div>
                    <hr>
                    <p class="text-secondary" >Si está seguro, presione el botón 'Realizar'. De lo contrario, presione 'Cancelar'.</p>
            `,
            showCancelButton: true,
            confirmButtonColor: '#198754',
            cancelButtonColor: '#6c757d',
            confirmButtonText: 'Si, Realizar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                handleCrearCredito();
            }
        });
    }

    const handleCrearCredito = async () => {

        setSpiner(true);

        await crear_credito(props.credito).then(response => {
            if(response.status === 201){

                cerrarModal('modalCredito');
        
                Swal.fire({
                    title: 'Crédito creado ('+response.data.id_credito+')',
                    text:  'Para consultar el estado del crédito, por favor, diríjase a la pestaña "Créditos".',
                    icon: "success",
                    confirmButtonColor: '#198754',
                    confirmButtonText: 'Aceptar',
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.href = '/creditos';
                    }
                });

            }else{
                alerta(response.data.status_message, response.data.error_message)
            }
        }).catch(error =>{

            Swal.fire({
                title: 'Error no controlado al crear el credito',
                text:  error.message+' ES POSIBLE QUE EL CREDITO SE HAYA CREADO, POR FAVOR CONFIRME EN EL MODULO DE CREDITOS.',
                icon: "error",
                confirmButtonColor: '#dc3545',
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = '/creditos';
                }
            });
        })

        setSpiner(false);
    }

    const handleCancelarFormulario = () =>{
        props.setCredito({ ...props.credito, ['tipo_garantia']: '' });
        setVistaFormulario('credito');
    }

    const handleCancelarCredito = () =>{
        window.location.href = '/simulacion';
    }

    const handleAbrirModalGarantiaCredito = (tipo_garantia) => {
        setVistaFormulario(tipo_garantia);
    }

    const handleDelteVehiculo = (indice) => {
        
        let vehiculosList = [...props.credito.vehiculos]; 
        vehiculosList.splice(indice, 1);
    
        props.setCredito({ 
            ...props.credito, 
            vehiculos: vehiculosList
        });
    };

    const handleDeltePropiedad = (indice) => {
        
        let propiedadList = [...props.credito.propiedades]; 
        propiedadList.splice(indice, 1);
    
        props.setCredito({ 
            ...props.credito, 
            propiedades: propiedadList
        });
    };

    const handleDelteLetra = (indice) => {

        let letraList = [...props.credito.letras]; 
        letraList.splice(indice, 1);
    
        props.setCredito({ 
            ...props.credito, 
            letras: letraList
        });
    };

    return(
        <React.Fragment>
            <div className="modal fade" id="modalCredito" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
                    <div className="modal-content" style={{'fontSize':'12px'}}>
                        {vista_formulario == 'credito' ?
                        <div className="modal-body">
                            <div className='row'>
                                <div className='col-md-7'>
                                    <div className="card mb-3 mt-0">
                                        <div className="card-body">
                                            <div className='d-flex align-items-center'>
                                                <i className="bi bi-upc mx-2"></i>
                                                <h6 className="card-subtitle  text-body-secondary  m-0">Información del credito</h6>
                                            </div>
                                            <hr></hr>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <label 
                                                        className="form-label" 
                                                        htmlFor="fecha_inicio">
                                                        Fecha de inicio 
                                                        <span className='text-danger'>*</span>
                                                    </label>
                                                    <input 
                                                        type="date" 
                                                        id="fecha_inicio" 
                                                        name="fecha_inicio" 
                                                        placeholder="0.0"
                                                        className="form-control form-control-sm" 
                                                        value={props.credito.fecha_inicio}
                                                        onChange={handleInputCreditoChange}
                                                        disabled={spiner}
                                                    />
                                                </div>
                                                <div className='col-md-3'>
                                                    <label 
                                                        className="form-label" 
                                                        htmlFor="fecha_ultimo_pago">
                                                        Fecha último Pago
                                                        <span className='text-danger'>*</span>
                                                    </label>
                                                    <input 
                                                        type="date" 
                                                        id="fecha_ultimo_pago" 
                                                        name="fecha_ultimo_pago" 
                                                        placeholder="0.0"
                                                        className="form-control form-control-sm" 
                                                        value={props.credito.fecha_ultimo_pago}
                                                        onChange={handleInputCreditoChange}
                                                        disabled={spiner}
                                                    />
                                                </div>
                                                <div className='col-md-3'>
                                                    <label 
                                                        className="form-label" 
                                                        htmlFor="fecha_fin">
                                                        Fecha Finalización
                                                    </label>
                                                    <input 
                                                        type="date" 
                                                        id="fecha_fin" 
                                                        name="fecha_fin" 
                                                        placeholder="0.0"
                                                        className="form-control form-control-sm"
                                                        disabled={true}
                                                        value={props.credito.fecha_fin}onChange={handleInputCreditoChange} 
                                                    />
                                                </div>                                                
                                            </div>                              
                                            <div className='row mt-3'>
                                                <div className='col-md-3'>
                                                    <label 
                                                        className="form-label" 
                                                        htmlFor="pago_total_credito">
                                                        Valor del credito
                                                    </label>
                                                    <input 
                                                        type="text" 
                                                        id="pago_total_credito" 
                                                        name="pago_total_credito"
                                                        className="form-control form-control-sm" 
                                                        disabled={true}
                                                        value={'$'+props.credito.valor}                                        
                                                        onChange={handleInputCreditoChange}
                                                    />
                                                </div>
                                                <div className='col-md-3'>
                                                    <label 
                                                        className="form-label" 
                                                        htmlFor="pago_total_credito">
                                                        Saldo Actual
                                                    </label>
                                                    <span className='text-danger'>*</span>
                                                    <input 
                                                        type="text" 
                                                        id="saldo_actual" 
                                                        name="saldo_actual"
                                                        className="form-control form-control-sm"
                                                        value={props.credito.saldo_actual}                                   
                                                        onChange={handleInputCreditoChange} 
                                                    />
                                                </div>
                                                <div className='col-md-3'>
                                                    <label 
                                                        className="form-label" 
                                                        htmlFor="cuota_mensual">
                                                        Cuota Mensual
                                                    </label>
                                                    <span className='text-danger'>*</span>
                                                    <input 
                                                        type="text" 
                                                        id="cuota_mensual" 
                                                        name="cuota_mensual"
                                                        className="form-control form-control-sm" 
                                                        disabled={props.credito.tipo_credito == 'CAPITAL'}
                                                        value={props.credito.cuota_mensual}                                   
                                                        onChange={handleInputCreditoChange} 
                                                    />
                                                </div>                                                
                                            </div>
                                            <div className='row mt-3'>
                                                <div className='col-md-6'>
                                                    <label className="form-label" htmlFor="tipo_credito">Tipo Credito:</label>
                                                    <select 
                                                        className="form-select form-select-sm" 
                                                        name="tipo_credito" 
                                                        value={props.credito.tipo_credito} 
                                                        onChange={handleInputCreditoChange}
                                                        disabled={true}   
                                                    >
                                                        <option value="COMPUESTO">INTERES COMPUESTO</option>
                                                        <option value="CAPITAL">INTERES CAPITAL</option>
                                                    </select>
                                                </div>
                                                <div className='col-md-2'>
                                                    <label 
                                                        className="form-label" 
                                                        htmlFor="interes">
                                                        Plazo
                                                    </label>
                                                    <input 
                                                        type="text" 
                                                        id="cantidad_cuotas" 
                                                        name="cantidad_cuotas"
                                                        className="form-control form-control-sm" 
                                                        disabled={true}
                                                        value={props.credito.cantidad_cuotas +' Meses'}                                        
                                                        onChange={handleInputCreditoChange} 
                                                    />
                                                </div>
                                                <div className='col-md-2'>
                                                    <label 
                                                        className="form-label" 
                                                        htmlFor="interes">
                                                        % Interes:
                                                    </label>
                                                    <input 
                                                        type="text" 
                                                        id="interes" 
                                                        name="interes"
                                                        className="form-control form-control-sm" 
                                                        disabled={true}
                                                        value={props.credito.interes+' %'}                                        
                                                        onChange={handleInputCreditoChange} 
                                                    />
                                                </div>      
                                            </div>
                                            <div className='row mt-3'>
                                                <div className='col-md-4'>
                                                    <label 
                                                        className="form-label" 
                                                        htmlFor="interes">
                                                        Nombre
                                                    </label>
                                                    <input 
                                                        type="text" 
                                                        id="interes" 
                                                        name="interes"
                                                        className="form-control form-control-sm" 
                                                        disabled={true}
                                                        value={props.cliente.nombre +' '+ props.cliente.apellido}
                                                    />
                                                </div>
                                                <div className='col-md-4'>
                                                    <label 
                                                        className="form-label" 
                                                        htmlFor="interes">
                                                        Documento
                                                    </label>
                                                    <input 
                                                        type="text" 
                                                        id="interes" 
                                                        name="interes"
                                                        className="form-control form-control-sm" 
                                                        disabled={true}
                                                        value={props.cliente.numero_documento}
                                                    />
                                                </div>
                                                <div className='row mt-3'>                           
                                                    <div className='col-md-12'>
                                                        <label 
                                                            className="form-label mt-1" 
                                                            htmlFor="observacion">
                                                            Observación 
                                                            <span className='text-danger'>*</span>
                                                        </label>
                                                        <textarea 
                                                            className="form-control" 
                                                            placeholder="Observacion" 
                                                            id="observacion" 
                                                            name="observacion" 
                                                            maxLength={200}
                                                            value={props.credito.observacion}  
                                                            onChange={handleInputCreditoChange}
                                                            >
                                                        </textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-5'>
                                    <div className="card mb-3 mt-0">
                                        <div className="card-body">
                                            <div className='d-flex align-items-center'>
                                                <i className="bi bi-clipboard-plus mx-2"></i>
                                                <h6 className="card-subtitle  text-body-secondary  m-0">Garantia del credito</h6>
                                            </div>
                                            <hr></hr>
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <label className="form-label" htmlFor="tipo_garantia">Selecciona un tipo de garantia para agregar:</label>
                                                    <select 
                                                        className="form-select form-select-sm" 
                                                        name="tipo_garantia"
                                                        value={props.credito.tipo_garantia}
                                                        onChange={handleInputCreditoChange}                                                        
                                                    >   
                                                        <option value="">-Seleccione-</option>
                                                        <option value="VEHICULO">Vehículo</option>
                                                        <option value="PROPIEDAD">Propiedad Raíz</option>
                                                        <option value="LETRA">Letra o contrato</option>
                                                    </select>
                                                </div>
                                            </div>

                                            { Array.isArray(props.credito.vehiculos) && props.credito.vehiculos.length > 0 ?
                                            <div className='row mt-2'>
                                                <div className='col-md-12'>
                                                    <div className="table-responsive">
                                                        <p className='m-0'>Vehículos</p>
                                                        <table className="table table-hover table-sm text-left " >
                                                            <thead>
                                                                <tr>
                                                                    <th>Marca</th>
                                                                    <th>linea</th>
                                                                    <th>valor</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {props.credito.vehiculos.map((v, index) => (
                                                                <tr key={index}>
                                                                    <td>{v.marca}</td>
                                                                    <td>{v.linea}</td>
                                                                    <td>${v.valor_vehiculo}</td>  
                                                                    <td>
                                                                        <a  href="#"
                                                                            className="link-danger icon-link-hover " 
                                                                            title="Ver informacion del cliente"
                                                                            onClick={ () => handleDelteVehiculo(index)}
                                                                        >
                                                                            <i className="bi bi-trash"></i>
                                                                        </a>
                                                                    </td>                                                                
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>:''}

                                            { Array.isArray(props.credito.propiedades) && props.credito.propiedades.length > 0 ?
                                            <div className='row mt-2'>
                                                <div className='col-md-12'>
                                                    <div className="table-responsive">
                                                        <p className='m-0'>Propiedades</p>
                                                        <table className="table table-hover table-sm text-left " >
                                                            <thead>
                                                                <tr>
                                                                    <th>Tipo</th>
                                                                    <th>Ubivación</th>
                                                                    <th>valor</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {props.credito.propiedades.map((p, index) => (
                                                                <tr key={index}>
                                                                    <td>{p.tipo_propiedad}</td>
                                                                    <td>{p.ubicacion}</td>
                                                                    <td>${p.valor_propiedad}</td>  
                                                                    <td>
                                                                        <a  href="#"
                                                                            className="link-danger icon-link-hover " 
                                                                            title="Ver informacion del cliente"
                                                                            onClick={ () => handleDeltePropiedad(index)}
                                                                        >
                                                                            <i className="bi bi-trash"></i>
                                                                        </a>
                                                                    </td>                                                                
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>:''}

                                            { Array.isArray(props.credito.letras) && props.credito.letras.length > 0 ?
                                            <div className='row mt-2'>
                                                <div className='col-md-12'>
                                                    <div className="table-responsive">
                                                        <p className='m-0'>Letras / Contrato</p>
                                                        <table className="table table-hover table-sm text-left " >
                                                            <thead>
                                                                <tr>
                                                                    <th>Tipo</th>
                                                                    <th>Fecha</th>
                                                                    <th>valor</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {props.credito.letras.map((l, index) => (
                                                                <tr key={index}>
                                                                    <td>{l.tipo_documento}</td>
                                                                    <td>{l.fecha_emision}</td>
                                                                    <td>${l.valor}</td>  
                                                                    <td>
                                                                        <a  href="#"
                                                                            className="link-danger icon-link-hover " 
                                                                            title="Ver informacion del cliente"
                                                                            onClick={ () => handleDelteLetra(index)}
                                                                        >
                                                                            <i className="bi bi-trash"></i>
                                                                        </a>
                                                                    </td>                                                                
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>:''}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :''}

                        {vista_formulario == 'VEHICULO' ?
                            <div className='modal-body'>
                                <div className="card mb-3 mt-0">
                                    <div className="card-body">
                                        <div className='d-flex align-items-center'>
                                            <i className="bi bi-car-front-fill mx-2"></i>
                                            <h6 className="card-subtitle  text-body-secondary  m-0">Por favor, ingrese los datos del vehículo.</h6>
                                        </div>
                                        <hr></hr>
                                        <div className='row'>
                                            <div className='col-md-2'>
                                                <label className="form-label"  htmlFor="marca">Marca</label>
                                                <span className='text-danger'>*</span>
                                                <input 
                                                    type="text"
                                                    placeholder='Toyota' 
                                                    id="marca" 
                                                    name="marca"
                                                    className="form-control form-control-sm"
                                                    value={vehiculo.marca}                                        
                                                    onChange={handleInputVehiculoChange} 
                                                />
                                            </div>
                                            <div className='col-md-2'>
                                                <label className="form-label"  htmlFor="linea">Linea</label>
                                                <span className='text-danger'>*</span>
                                                <input 
                                                    type="text"
                                                    placeholder='Corrolla' 
                                                    id="linea" 
                                                    name="linea"
                                                    className="form-control form-control-sm"
                                                    value={vehiculo.linea}                                        
                                                    onChange={handleInputVehiculoChange} 
                                                />
                                            </div>
                                            <div className='col-md-2'>
                                                <label className="form-label" htmlFor="placa">Placa</label>
                                                <input 
                                                    type="text"
                                                    placeholder='XXX111' 
                                                    id="placa" 
                                                    name="placa"
                                                    className="form-control form-control-sm"
                                                    value={vehiculo.placa}                                        
                                                    onChange={handleInputVehiculoChange}
                                                    maxLength={7}
                                                />
                                            </div>
                                            <div className='col-md-2'>
                                                <label className="form-label" htmlFor="modelo">Modelo</label>
                                                <input 
                                                    type="date"
                                                    id="modelo" 
                                                    name="modelo"
                                                    className="form-control form-control-sm"
                                                    value={vehiculo.modelo}                                        
                                                    onChange={handleInputVehiculoChange} 
                                                />
                                            </div>
                                            <div className='col-md-2'>
                                                <label className="form-label"  htmlFor="color">Color</label>
                                                <input 
                                                    type="text"
                                                    placeholder='Rojo' 
                                                    id="color" 
                                                    name="color"
                                                    className="form-control form-control-sm"
                                                    value={vehiculo.color}                                        
                                                    onChange={handleInputVehiculoChange} 
                                                />
                                            </div>                                            
                                            <div className='col-md-2'>
                                                <label className="form-label" htmlFor="cilindraje">Cilindraje</label>
                                                <input 
                                                    type="number"
                                                    placeholder='1600' 
                                                    id="cilindraje" 
                                                    name="cilindraje"
                                                    className="form-control form-control-sm"
                                                    value={vehiculo.cilindraje}                                        
                                                    onChange={handleInputVehiculoChange} 
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className='row mt-2'>
                                            <div className='col-md-3'>
                                                <label className="form-label" htmlFor="motor">Motor</label>
                                                <input 
                                                    type="text"
                                                    placeholder='ABCDEFGHIJK' 
                                                    id="motor" 
                                                    name="motor"
                                                    className="form-control form-control-sm"
                                                    value={vehiculo.motor}                                        
                                                    onChange={handleInputVehiculoChange} 
                                                />
                                            </div>
                                            <div className='col-md-3'>
                                                <label className="form-label" htmlFor="chasis">Chasis</label>
                                                <input 
                                                    type="text"
                                                    placeholder='ABCDEFGHIJK' 
                                                    id="chasis" 
                                                    name="chasis"
                                                    className="form-control form-control-sm"
                                                    value={vehiculo.chasis}                                        
                                                    onChange={handleInputVehiculoChange} 
                                                />
                                            </div>
                                            <div className='col-md-3'>
                                                <label className="form-label" htmlFor="serie">Serie</label>
                                                <input 
                                                    type="text"
                                                    placeholder='ABCDEFGHIJK' 
                                                    id="serie" 
                                                    name="serie"
                                                    className="form-control form-control-sm"
                                                    value={vehiculo.serie}                                        
                                                    onChange={handleInputVehiculoChange} 
                                                />
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-md-3'>
                                                <label className="form-label" htmlFor="capacidad">Capacidad</label>
                                                <input 
                                                    type="text"
                                                    placeholder='5' 
                                                    id="capacidad" 
                                                    name="capacidad"
                                                    className="form-control form-control-sm"
                                                    value={vehiculo.capacidad}                                    
                                                    onChange={handleInputVehiculoChange} 
                                                />
                                            </div>
                                            <div className='col-md-3'>
                                                <label className="form-label" htmlFor="kilometraje">Kilometraje</label>
                                                <input 
                                                    type="number"
                                                    placeholder='10.000'
                                                    id="kilometraje" 
                                                    name="kilometraje"
                                                    className="form-control form-control-sm"
                                                    value={vehiculo.kilometraje}                                    
                                                    onChange={handleInputVehiculoChange} 
                                                />
                                            </div>
                                            <div className='col-md-3'>
                                                <label className="form-label" htmlFor="valor">Valor</label>
                                                <span className='text-danger'>*</span>
                                                <input 
                                                    type="text"
                                                    placeholder='$1.000.000'
                                                    id="valor_vehiculo" 
                                                    name="valor_vehiculo"
                                                    className="form-control form-control-sm"
                                                    value={vehiculo.valor_vehiculo}                                    
                                                    onChange={handleInputVehiculoChange} 
                                                />
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-md-6'>
                                                <label className="form-label" htmlFor="chasis">Observación</label>
                                                <textarea 
                                                    rows={4}
                                                    type="text"
                                                    id="observacion" 
                                                    name="observacion"
                                                    className="form-control form-control-sm"
                                                    value={vehiculo.observacion}                                    
                                                    onChange={handleInputVehiculoChange} 
                                                    maxLength={300}
                                                />
                                            </div>
                                            <div className='col-md-2' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                                                <button 
                                                    type="button" 
                                                    className="btn btn-success"
                                                    onClick={handleGuardarVehiculo}
                                                >
                                                    Guardar vehículo
                                                </button>   
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :''}

                        {vista_formulario == 'PROPIEDAD' ?
                            <div className='modal-body'>
                                <div className="card mb-3 mt-0">
                                    <div className="card-body">
                                        <div className='d-flex align-items-center'>
                                            <i className="bi bi-house-add-fill mx-2"></i>
                                            <h6 className="card-subtitle  text-body-secondary  m-0">Por favor, ingrese los datos de la propiedad raiz.</h6>
                                        </div>
                                        <hr></hr>
                                        <div className='row'>

                                            <div className='col-md-3'>
                                                <label className="form-label"  htmlFor="tipo_propiedad">Tipo Propiedad</label>
                                                <span className='text-danger'>*</span>
                                                <input 
                                                    type="text"
                                                    placeholder='Apartamento' 
                                                    id="tipo_propiedad" 
                                                    name="tipo_propiedad"
                                                    className="form-control form-control-sm"
                                                    value={propiedad.tipo_propiedad}                                        
                                                    onChange={handleInputPropiedadChange} 
                                                />
                                            </div>

                                            <div className='col-md-3'>
                                                <label className="form-label"  htmlFor="ubicacion">Ubicación</label>
                                                <span className='text-danger'>*</span>
                                                <input 
                                                    type="text"
                                                    placeholder='Medellin' 
                                                    id="ubicacion" 
                                                    name="ubicacion"
                                                    className="form-control form-control-sm"
                                                    value={propiedad.ubicacion}                                      
                                                    onChange={handleInputPropiedadChange} 
                                                />
                                            </div>

                                            <div className='col-md-6'>
                                                <label className="form-label"  htmlFor="direccion">Dirección</label>
                                                <input 
                                                    type="text"
                                                    placeholder='Calle 36 #30-5 Ciudad Jardin Torre 8 apto 802' 
                                                    id="direccion" 
                                                    name="direccion"
                                                    className="form-control form-control-sm"
                                                    value={propiedad.direccion}                                      
                                                    onChange={handleInputPropiedadChange} 
                                                />
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-md-3'>
                                                <label className="form-label"  htmlFor="numero_escritura"># Escritura</label>
                                                <input 
                                                    type="text"
                                                    placeholder='5678/2022' 
                                                    id="numero_escritura" 
                                                    name="numero_escritura"
                                                    className="form-control form-control-sm"
                                                    value={propiedad.numero_escritura}                                      
                                                    onChange={handleInputPropiedadChange} 
                                                />
                                            </div>

                                            <div className='col-md-3'>
                                                <label className="form-label"  htmlFor="registro_catastral">Registro catastral</label>
                                                <input 
                                                    type="text"
                                                    placeholder='01234567890000ABCDEF12345' 
                                                    id="registro_catastral" 
                                                    name="registro_catastral"
                                                    className="form-control form-control-sm"
                                                    value={propiedad.registro_catastral}                                      
                                                    onChange={handleInputPropiedadChange} 
                                                />
                                            </div>

                                            <div className='col-md-2'>
                                                <label className="form-label"  htmlFor="tamano_propiedad">Tamaño m²</label>
                                                <input 
                                                    type="number"
                                                    placeholder='0' 
                                                    id="tamano_propiedad" 
                                                    name="tamano_propiedad"
                                                    className="form-control form-control-sm"
                                                    value={propiedad.tamano_propiedad}                                      
                                                    onChange={handleInputPropiedadChange} 
                                                />
                                            </div>

                                            <div className='col-md-2'>
                                                <label className="form-label"  htmlFor="ano_construccion">Año construcción</label>
                                                <input 
                                                    type="date"
                                                    placeholder='0' 
                                                    id="ano_construccion" 
                                                    name="ano_construccion"
                                                    className="form-control form-control-sm"
                                                    value={propiedad.ano_construccion}                                      
                                                    onChange={handleInputPropiedadChange} 
                                                />
                                            </div>

                                            <div className='col-md-2'>
                                                <label className="form-label"  htmlFor="valor_propiedad">Valor</label>
                                                <input 
                                                    type="text"
                                                    placeholder='0' 
                                                    id="valor_propiedad" 
                                                    name="valor_propiedad"
                                                    className="form-control form-control-sm"
                                                    value={propiedad.valor_propiedad}                                      
                                                    onChange={handleInputPropiedadChange} 
                                                />
                                            </div>

                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-md-6'>
                                                <label className="form-label" htmlFor="observacion">Observación</label>
                                                <textarea 
                                                    rows={4}
                                                    type="text"
                                                    id="observacion" 
                                                    name="observacion"
                                                    className="form-control form-control-sm"
                                                    value={propiedad.observacion}                                    
                                                    onChange={handleInputVehiculoChange} 
                                                    maxLength={300}
                                                />
                                            </div>
                                            <div className='col-md-3' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                                                <button 
                                                    type="button" 
                                                    className="btn btn-success"
                                                    onClick={handleGuardarPropiedad}
                                                >
                                                    Guardar propiedad
                                                </button>   
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :''}

                        {vista_formulario == 'LETRA' ?
                            <div className='modal-body'>
                                <div className="card mb-3 mt-0">
                                    <div className="card-body">
                                        <div className='d-flex align-items-center'>
                                            <i className="bi bi-ui-checks mx-2"></i>
                                            <h6 className="card-subtitle  text-body-secondary  m-0">Por favor, ingrese los datos de la letra o contrato</h6>
                                        </div>
                                        <hr></hr>
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <label className="form-label"  htmlFor="tipo_documento">Tipo Documento</label>
                                                <span className='text-danger'>*</span>
                                                <input 
                                                    type="text"
                                                    placeholder='Letra/Contrato' 
                                                    id="tipo_documento" 
                                                    name="tipo_documento"
                                                    className="form-control form-control-sm"
                                                    value={letra.tipo_documento}                                        
                                                    onChange={handleInputLetraChange} 
                                                />
                                            </div>

                                            <div className='col-md-3'>
                                                <label className="form-label"  htmlFor="fecha_emision">Fecha emision</label>
                                                <span className='text-danger'>*</span>
                                                <input 
                                                    type="date"
                                                    placeholder='' 
                                                    id="fecha_emision" 
                                                    name="fecha_emision"
                                                    className="form-control form-control-sm"
                                                    value={letra.fecha_emision}                                      
                                                    onChange={handleInputLetraChange} 
                                                />
                                            </div>

                                            <div className='col-md-3'>
                                                <label className="form-label"  htmlFor="fecha_vencimiento">Fecha vencimiento</label>
                                                <input 
                                                    type="date"
                                                    placeholder='' 
                                                    id="fecha_vencimiento" 
                                                    name="fecha_vencimiento"
                                                    className="form-control form-control-sm"
                                                    value={letra.fecha_vencimiento}                                      
                                                    onChange={handleInputLetraChange} 
                                                />
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-md-3'>
                                                <label className="form-label"  htmlFor="nombre_emisor">Nombre emisor</label>
                                                <span className='text-danger'>*</span>
                                                <input 
                                                    type="text"
                                                    placeholder='' 
                                                    id="nombre_emisor" 
                                                    name="nombre_emisor"
                                                    className="form-control form-control-sm"
                                                    value={letra.nombre_emisor}                                        
                                                    onChange={handleInputLetraChange} 
                                                />
                                            </div>

                                            <div className='col-md-3'>
                                                <label className="form-label"  htmlFor="nombre_beneficiario">Nombre Beneficiario</label>
                                                <input 
                                                    type="text"
                                                    placeholder='' 
                                                    id="nombre_beneficiario" 
                                                    name="nombre_beneficiario"
                                                    className="form-control form-control-sm"
                                                    value={letra.nombre_beneficiario}                                      
                                                    onChange={handleInputLetraChange} 
                                                />
                                            </div>

                                            <div className='col-md-3'>
                                                <label className="form-label"  htmlFor="valor">Valor</label>
                                                <span className='text-danger'>*</span>
                                                <input 
                                                    type="text"
                                                    placeholder='' 
                                                    id="valor" 
                                                    name="valor"
                                                    className="form-control form-control-sm"
                                                    value={letra.valor}                                      
                                                    onChange={handleInputLetraChange} 
                                                />
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-md-6'>
                                                <label className="form-label" htmlFor="observacion">Observación</label>
                                                <textarea 
                                                    rows={4}
                                                    type="text"
                                                    id="observacion" 
                                                    name="observacion"
                                                    className="form-control form-control-sm"
                                                    value={letra.observacion}                                    
                                                    onChange={handleInputLetraChange} 
                                                    maxLength={300}
                                                />
                                            </div>
                                            <div className='col-md-3' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                                                <button 
                                                    type="button" 
                                                    className="btn btn-success"
                                                    onClick={handleGuardarLetra}
                                                >
                                                    Guardar documento
                                                </button>   
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :''}

                        <div className="modal-footer">

                            {vista_formulario == 'credito' ?
                                <button type="button" className="btn btn-secondary btn-lg" onClick={handleCancelarCredito}>Cancelar</button>
                            :''}

                            {vista_formulario != 'credito' ?
                                <button type="button" className="btn btn-secondary btn-lg" onClick={handleCancelarFormulario}>Cancelar</button>
                            :''}

                            { vista_formulario == 'credito' ?
                                <>
                                    { spiner ?
                                    <button className="btn btn-success btn-lg" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                        <span role="status"> Creando Credito...</span>
                                    </button>:
                                    <button 
                                            type="submit" 
                                            className="btn btn-success btn-lg"                                    
                                            onClick={handleConfirmarCredito}
                                            disabled={!Array.isArray(props.credito.credito_item)}
                                        >
                                            Realizar credito
                                    </button>
                                    
                                    }
                                </> 
                            : ''}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapSateToProps = state => {
    return {
        credito: state.credito,
        cliente: state.cliente
    }
}

const mapDispatchToProps  = {
    setCredito,
    setSaldosEmpresa
};

export default connect(mapSateToProps, mapDispatchToProps)(ModalIniciarCredito)