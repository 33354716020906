import React,{ useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { get_bancos} from "../../services/Login"
import { crear_medio_pago, editar_medio_pago, eliminar_medio_pago, lista_medios_pago} from "../../services/Ajustes"
import { connect } from 'react-redux';
import { setIdCredito } from '../../actions';
import { toast, alerta, abrirModal, cerrarModal, formatearMonedaValue, formatDate } from "../../services/utilities"
import Swal  from 'sweetalert2';

function ModalMediosPago(props) {

    useEffect(() => {
        handleConsultarBancos();
        handleConsultarMediosPago();
    }, []);

    const [spiner, setSpiner] = useState(false);
    const [bancos, setBnacos] = useState([]);
    const [medios, setMedios] = useState([]);
    const [accion, setAccion] = useState('crear');

    const [form, setForm] = useState({
        "id_empresa_medio_pago":"",
        "banco_id" : "",
        "tipo_cuenta":"",
        "numero_cuenta" :"",
        "estado_id" :"1",
        "color" :"primary",
        "detalle":""
    });

    const handleInputChange = (event) => {

        const { name, value, type } = event.target;
        setForm({ ...form, [name]: value });
    };

    
    const handleConsultarBancos = async () => {
        
        try {
            await get_bancos().then(response => {
                if(response.status === 200){
                    setBnacos(response.data.data);
                }else{
                    toast('Error al cargar la lista de bancos','Por favor intente nuevamente')
                }
            });
         }catch (error) {
            const errorMessage = error.message || 'Error desconocido';
            toast('Error al cargar la lista de bancos', `Por favor intente nuevamente. Detalles del error: ${errorMessage}`);
        }
    }

    const handleConsultarMediosPago = async () => {
        
        try {
            await lista_medios_pago().then(response => {
                if(response.status === 200){
                    setMedios(response.data.data);
                }else{
                    toast('Error al cargar la lista de medios de pagos','Por favor intente nuevamente')
                }
            });
         }catch (error) {
            const errorMessage = error.message || 'Error desconocido';
            toast('Error al cargar la lista de bancos', `Por favor intente nuevamente. Detalles del error: ${errorMessage}`);
        }
    }

    const handleCrearMedioPago = async () => {

        if(form.banco_id == ''){
            toast('Campos requeridos *','Por favor seleccione un banco');
            return;
        }

        if(form.tipo_cuenta == ''){
            toast('Campos requeridos *','Por favor seleccione un tipo de cuenta');
            return;
        }

        if(form.numero_cuenta.length < 4){
            toast('Campos requeridos *','El número de cuenta debe tener mínimo 4 caracteres');
            return;
        }

        setSpiner(true);

        try {
            await crear_medio_pago(form).then(response => {

                if(response.status === 200 && response.data.status_message == 'OK'){
                    handleConsultarMediosPago();
                    setForm({
                        "id_empresa_medio_pago":"",
                        "banco_id" : "",
                        "tipo_cuenta":"",
                        "numero_cuenta" :"",
                        "estado_id" :"1",
                        "color" :"primary",
                        "detalle":""
                    });

                    alerta('Medio de pago creado de forma correcta','','success');
                    cerrarModal('ModalMediosPago');

                }else{
                    alerta(response.data.status_message, response.data.error_message);
                }

            });
         }catch (error) {
            const errorMessage = error.message || 'Error desconocido';
            toast('Error al cargar la lista de bancos', `Por favor intente nuevamente. Detalles del error: ${errorMessage}`);
        }

        setSpiner(false);
    }

    const handleEditarMedioPago = async () => {

        if(form.banco_id == ''){
            toast('Campos requeridos *','Por favor seleccione un banco');
            return;
        }

        if(form.tipo_cuenta == ''){
            toast('Campos requeridos *','Por favor seleccione un tipo de cuenta');
            return;
        }

        if(form.numero_cuenta.length < 4){
            toast('Campos requeridos *','El número de cuenta debe tener mínimo 4 caracteres');
            return;
        }

        setSpiner(true);
        try {
            await editar_medio_pago(form).then(response => {

                if(response.status === 200 && response.data.status_message == 'OK'){
                    handleConsultarMediosPago();
                    setForm({
                        "id_empresa_medio_pago":"",
                        "banco_id" : "",
                        "tipo_cuenta":"",
                        "numero_cuenta" :"",
                        "estado_id" :"1",
                        "color" :"primary",
                        "detalle":""
                    });

                    setAccion('crear');
                    alerta('Medio de pago editado de forma correcta','','success');
                    cerrarModal('ModalMediosPago');

                }else{
                    alerta(response.data.status_message, response.data.error_message);
                }

            });
         }catch (error) {
            const errorMessage = error.message || 'Error desconocido';
            toast('Error al editar el medio de pago', `Por favor intente nuevamente. Detalles del error: ${errorMessage}`);
        }

        setSpiner(false);
    }

    const handleElminarMedioPago = (id_empresa_medio_pago) =>{

        Swal.fire({
            title: '¿ Esta seguro de eliminar el medio de pago ?',
            text: "Si esta seguro presione el boton OK de lo contrario Cancelar",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancelar'
        }).then((result) => {

            if (result.isConfirmed) {
                eliminar_medio_pago(id_empresa_medio_pago).then(response => {
                    if(response.status === 200 && response.data.status_message == 'OK'){
                        handleConsultarMediosPago();
                        alerta('Medio de pago eliminado','','success');
                        cerrarModal('ModalMediosPago');
                    }else{                        
                        alerta(response.data.error_message)
                    }
                })
                
            }
        })
    }
    

    const handleCargarDatosEditar = (data) => {
        setForm(data);
        setAccion('editar');
    }

    const handleCnacelar = () => {
        setAccion('crear'); 
        setForm({
            "id_empresa_medio_pago":"",
            "banco_id" : "",
            "tipo_cuenta":"",
            "numero_cuenta" :"",
            "estado_id" :"1",
            "color" :"primary",
            "detalle":""
        });
    }

    return(

        <React.Fragment>
           <div className="modal fade" id="ModalMediosPago" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header bg-header-modal">
                            <h5 id="staticBackdropLabel" className='m-0'>Configuración de medios de pago para la app</h5>
                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <label className="form-label"  htmlFor="banco_id">Banco ingreso: <span className='text-danger'>*</span></label>
                                            <select 
                                                className="form-select form-select-md text-center" 
                                                name="banco_id"
                                                id="banco_id" 
                                                value={form.banco_id} 
                                                onChange={handleInputChange}                                     
                                            >
                                                <option value="">- Seleccione -</option>
                                                {Array.isArray(bancos) ? bancos.map((item, index) => (
                                                    <option key={index} value={item.id_banco}>{item.nombre}</option>
                                                )) : ''}
                                            </select>
                                        </div>
                                        <div className='col-md-12 mt-3'>
                                            <label className="form-label"  htmlFor="tipo_cuenta">Tipo cuenta: <span className='text-danger'>*</span></label>
                                            <select 
                                                className="form-select form-select-md text-center" 
                                                name="tipo_cuenta"
                                                id="tipo_cuenta" 
                                                value={form.tipo_cuenta} 
                                                onChange={handleInputChange}                                     
                                            >
                                                <option value="">- Seleccione -</option>
                                                <option  value="Cuenta de Ahorros">Cuenta de Ahorros</option>
                                                <option  value="Cuenta Corriente">Cuenta Corriente</option>
                                                <option  value="Billeteras">Billeteras</option>
                                                <option  value="Convenio">Convenio</option>
                                            </select>
                                        </div>
                                        <div className='col-md-12 mt-3'>
                                            <label className="form-label" >Numero Cuenta: <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <input 
                                                    type="text" 
                                                    className="form-control form-control-md" 
                                                    id="numero_cuenta" 
                                                    name="numero_cuenta" 
                                                    value={form.numero_cuenta}
                                                    onChange={handleInputChange} 
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-6 mt-3'>
                                                <label className="form-label"  htmlFor="tipo_cuenta">Estado</label>
                                            <select 
                                                className="form-select form-select-md text-center" 
                                                name="estado_id"
                                                id="estado_id" 
                                                value={form.estado_id}
                                                onChange={handleInputChange}                                     
                                            >
                                                <option  value="1">Activo</option>
                                                <option  value="0">Inactivo</option>
                                            </select>
                                        </div>
                                        <div className='col-md-6 mt-3'>
                                            <label className="form-label"  htmlFor="color">Color</label>
                                            <select 
                                                className="form-select form-select-md text-center" 
                                                name="color"
                                                id="color" 
                                                value={form.color} 
                                                onChange={handleInputChange}                                     
                                            >
                                                <option  value="primary">Azul</option>
                                                <option  value="secondary">Azul oscuro</option>
                                                <option  value="tertiary">Morado</option>
                                                <option  value="success">Verde</option>
                                                <option  value="warning">Amarillo</option>
                                                <option  value="danger">Rojo</option>
                                                <option  value="light">Blanco</option>
                                                <option  value="medium">Gris</option>
                                                <option  value="dark">Negro</option>
                                            </select>
                                        </div>
                                        <div className='col-md-12 mt-3'>
                                            <label className="form-label" >Observación:</label>
                                            <textarea 
                                                type="text" 
                                                className="form-control form-control-md" 
                                                maxLength={50}
                                                id="detalle" 
                                                name="detalle" 
                                                value={form.detalle}
                                                onChange={handleInputChange} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-8'>
                                    <table className='table table-hover' style={{fontSize:'12px'}}>
                                        <thead>
                                            <tr>
                                                <th>Banco</th>
                                                <th>Tipo</th>
                                                <th>Numero Cuenta</th>
                                                <th>Descripción</th>
                                                <th>Estado</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {Array.isArray(medios) ? medios.map((item, index) => (
                                            <tr key={index} className='align-middle'>
                                                <td>{item.banco}</td>
                                                <td>{item.tipo_cuenta}</td>
                                                <td>{item.numero_cuenta}</td>
                                                <td>{item.detalle}</td>
                                                <td><p className={item.estado_id == 1 ? 'text-success m-0' : 'text-danger m-0'}><b>{item.estado}</b></p></td>
                                                <td><button type="button" className="btn btn-warning btn-sm" onClick={() => handleCargarDatosEditar(item)}>Editar</button></td>
                                                <td><button type="button" className="btn btn-danger btn-sm" onClick={() => handleElminarMedioPago(item.id_empresa_medio_pago)}>Eliminar</button></td>
                                            </tr>
                                        )) : 
                                            <p className='text-center'>No hay cmedios de pago confiugrados.</p>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleCnacelar}>Cancelar</button>
                            {accion === 'crear' ?
                                <button 
                                    className="btn btn-success"
                                    disabled={spiner==true}
                                    onClick={handleCrearMedioPago}
                                    >Crear medio de pago
                                </button>
                            :
                                <button 
                                    className="btn btn-warning"
                                    disabled={spiner==true}
                                    onClick={handleEditarMedioPago}
                                    >Editar medio de pago
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapDispatchToProps  = {
    setIdCredito
};

export default connect(null, mapDispatchToProps)(ModalMediosPago)
