export const setDataClientes = payload => ({
    type: "SET_DATA_CLIENTES",
    payload,
});

export const setSaldosEmpresa = payload => ({
    type: "SET_SALDOS_EMPRESA",
    payload,
});

export const setCredito = payload => ({
    type: "SET_CREDITO",
    payload,
});

export const setCliente = payload => ({
    type: "SET_CLIENTE",
    payload,
});

export const setIdCredito = payload => ({
    type: "SET_ID_CREDITO",
    payload,
});

export const setUsuario = payload => ({
    type: "SET_USUARIO",
    payload,
});

export const setUsuarioEditar= payload => ({
    type: "SET_USUARIO_EDITAR",
    payload,
});