import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { setSaldosEmpresa } from '../../actions'
import { traslado_saldo_intereses } from "../../services/Ajustes"
import { alerta, toast, cerrarModal } from "../../services/utilities";
import Swal  from 'sweetalert2';
import { validarSegundaClave } from '../../services/Login';

    function TrasladoSaldo(props){

        const [form, setForm] = useState({
            "valor": "",
            "observacion": ""
        }
    );

    const [segundaClave, setSegundaClave] = useState('');
    const [autenticado, setAutenticado] = useState(false);
    const [spiner, setSpiner] = useState(false);
    const [verMensaje, setVerMensaje] = useState(false);

    const handleInputChange = (event) => {

        const { name, value } = event.target;
        if(name === 'valor'){
            var inputValue = value.replace(/\D/g, '');
            const formattedNumber = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            setForm({ ...form, [name]: formattedNumber });
        }else{
            setForm({ ...form, [name]: value });
        }
    };

    const handleConfirmarTransaccion = () => {

        if(form.valor == '' || form.valor == '0'){
            toast('Campos Requeridos','El valor debe ser mayor a cero', true)
            return;
        }

        if(form.observacion == ''){
            toast('Campos Requeridos','Digite una observación', true)
            return;
        }

        Swal.fire({
            html: `
                    <p><b>Por favor, verifique la información de la transacción.</b></p>
                    <hr></hr>   
                    <div class='d-flex align-items-center justify-content-between  m-0'>
                        <h6 class="card-subtitle mb-2 text-body-secondary">Tipo de transacción</h6>
                        <p class='m-0 text-success'><b>TRASLADO SALDO</b></p>
                    </div>               
                    <div class='d-flex align-items-center justify-content-between  m-0'>
                        <h6 class="card-subtitle mb-2 text-body-secondary">Valor</h6>
                        <p class='m-0 text-success'><b>$${form.valor}</b></p>
                    </div>
                    <hr>
                    <p class="text-secondary" >Si está seguro, presione el botón 'Realizar'. De lo contrario, presione 'Cancelar'.</p>
            `,
            showCancelButton: true,
            confirmButtonColor: '#198754',
            cancelButtonColor: '#6c757d',
            confirmButtonText: 'Si, Realizar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                handleGuardarTransaccion();
            }
        });
    }


    const handleGuardarTransaccion = async () =>{
    
        try {

            await traslado_saldo_intereses(form).then(response => {

                if(response.status === 201){
                
                    cerrarModal('ModalTrasladoSaldo');
                    alerta('Transacción Exitosa ('+ response.data.id_transaccion+')','Su saldo ya se encuentra disponible','success');
                    setAutenticado(false);
                    props.setSaldosEmpresa({
                        "disponible": response.data.saldos_empresa.disponible,
                        "prestado": response.data.saldos_empresa.prestado,
                        "intereses": response.data.saldos_empresa.intereses
                    });

                    setForm({
                        "valor": "",
                        "observacion": ""
                    });

                }else{
                    alerta('Error al realizar la transaccion',response.data.status_message);
                }
            });
         }catch (error) {
            const errorMessage = error.message || 'Error desconocido';
            alerta('Error al realizar la trasnaccion', `Por favor intente nuevamente. Detalles del error: ${errorMessage}`);
        }
    }

    const handleInputSegundaClave = (event) => {
        const { name, value } = event.target;
        setSegundaClave(value);
    };

    const handleValidarSegundaClave = async () => {

        if(segundaClave.length < 4){
            alerta('Ingrese una clave valida', '');
            return;
        }

        setSpiner(true);
        setVerMensaje(false);

        try {

            await validarSegundaClave(segundaClave).then( response => {
                if(response.status !== undefined && response.status === 200){
                    
                    if(response.data.status_message === 'OK'){
                        setAutenticado(true);
                        toast('¡ Perfecto !','Clave validada',true,'success');
                        setSegundaClave('');
                    }else{
                        setAutenticado(false);
                        setVerMensaje(true);
                    }
                }else{
                    alerta('Error al validar la segunda clave en la base de datos');
                }
            });

         }catch (error) {
            alerta('Error no controlado al validar la segunda clave (loterias/nueva/handleValidarSegundaClave) ->'+ error);
        }finally {
            setSpiner(false);
        }
    }

    return(
        <React.Fragment>
            <div className="modal modal-lg fade" id="ModalTrasladoSaldo" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-header bg-header-modal">
                        <h5 className="m-0">Traslado de saldo</h5>
                    </div>
                    <div className="modal-body">
                        { autenticado == true ?
                            <>
                                <div className='col-md-4'>
                                    <label 
                                        className="form-label" 
                                        htmlFor="valor">
                                        Valor: 
                                        <span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        type="text" 
                                        id="valor" 
                                        name="valor" 
                                        placeholder="$0" 
                                        className="form-control form-control-lg" 
                                        value={form.valor}onChange={handleInputChange} 
                                    />
                                    
                                </div>
                                <div className='col-md-12 mt-2'>
                                    <label 
                                        className="form-label" 
                                        htmlFor="observacion">
                                        Observación 
                                        <span className='text-danger'>*</span>
                                    </label>
                                    <textarea 
                                        className="form-control form-control-lg" 
                                        placeholder="Observacion" 
                                        id="observacion" 
                                        name="observacion" 
                                        maxLength={100}
                                        value={form.observacion}  
                                        onChange={handleInputChange}
                                        >
                                    </textarea>
                                </div>
                            </>:                            
                            <>
                                <h4 className='text-secondary text-center m-0'>Para continuar por favor ingrese la segunda clave.</h4>
                                <p className='text-secondary text-center m-0'>Este módulo se encuentra protegido por un doble factor de autenticación.</p>
                                <br></br>
                                <div className='row  justify-content-md-center'>
                                    <div className='col-md-4'>
                                        <div className="form-floating mb-3">
                                            <input type="password" className="form-control" id="segunda_clave" name="segunda_clave" value={segundaClave}  onChange={handleInputSegundaClave}  />
                                            <label htmlFor="floatingName">Segunda Clave</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row  justify-content-md-center'>
                                    <div className="col-md-4 text-center">
                                        <button type="button" className="btn btn-success" onClick={handleValidarSegundaClave}>Validar Clave</button>
                                    </div>
                                </div>
                                {spiner ?
                                <div className="d-flex justify-content-center align-items-center my-4">
                                    <div className="spinner-border" role="status"></div>                 
                                </div>
                                :''}
                                {verMensaje ?
                                <div className='text-center text-danger my-4'>
                                    <h3>Clave incorrecta</h3>
                                    <p>Por favor, ingrese nuevamente la segunda clave de forma correcta.</p>
                                </div>
                                :''}
                            </>
                        }
                    </div>
                    <div className="modal-footer">
                        <button 
                            type="button" 
                            className="btn btn-secondary btn-lg" 
                            data-bs-dismiss="modal"
                            onClick={()=> setAutenticado(false)}
                        >
                            Cancelar
                        </button>
                        {autenticado == true ?<button type="button" className="btn btn-success btn-lg" onClick={handleConfirmarTransaccion}>Aceptar</button>:''}
                    </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapDispatchToProps  = {
    setSaldosEmpresa,
};

export default connect(null, mapDispatchToProps)(TrasladoSaldo)
