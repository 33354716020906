import React,{ useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { get_credito } from "../../services/Credito"
import { setIdCredito } from '../../actions';
import { alerta, formatearMonedaValue } from "../../services/utilities"

function ModalPagar(props) {

    useEffect(() => {

        if(props.idCredito){
            setSpiner(true);
            handleConsultarCredito()
            setNoFoundCredito(false)
        }
    }, [props.idCredito]);

    const [spiner, setSpiner] = useState(false);
    const [hayData, setHayData] = useState(false);
    const [noFoundCredito, setNoFoundCredito] = useState(false);
    const [credito, setCredito] = useState({
        "credito":{},
        "cliente":{}
    });

    const handleConsultarCredito = async () => {

        try {
            await get_credito(props.idCredito).then(response => {
                if(response.status === 200 && response.data.status_message == 'ok'){
                    setHayData(true);
                    setCredito(response.data.data);
                    setNoFoundCredito(false);
                }else{
                    setNoFoundCredito(true)
                }
            });
         }catch (error) {
            const errorMessage = error.message || 'Error desconocido';
            alerta('Error al cargar la información del crédito', `Por favor intente nuevamente. Detalles del error: ${errorMessage}`);
        }

        setSpiner(false);
    }
    
    return(

        <React.Fragment>
           <div className="modal fade" id="modalGestionCredito" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true" style={{fontSize:'12px'}}>
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                    <div className="modal-content">                    
                        <div className="modal-header bg-header-modal">
                            <h5 className='m-0'>Gestion de credito <span className="badge text-bg-info"><i className="bi bi-upc"></i>{credito.credito.id_credito}</span></h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {spiner ?
                            <div className="d-flex align-items-center mb-3">
                                <strong role="status" className='text-secondary'>Cargando Informacion...</strong>
                                <div className="spinner-border ms-auto text-secondary" aria-hidden="true"></div>
                            </div>
                            :''}
                            { hayData ?
                                <>
                                    <div className='row'>                                        
                                        <div className='col-md-5'>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className='d-flex align-items-center justify-content-start'>
                                                        <i className="bi bi-clipboard-check mx-2"></i>
                                                        <h6 className="card-subtitle text-body-secondary m-0">Resumen del credito</h6>
                                                    </div>
                                                    <hr></hr>
                                                    <div className='d-flex align-items-center justify-content-between  mb-1'>
                                                        <h6 className="card-subtitle m-0 text-body-secondary">% Interes</h6>
                                                        <p className='m-0'><b>{credito.credito.interes}%</b></p>
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-between  mb-1'>
                                                        <h6 className="card-subtitle m-0 text-body-secondary">Cantidad de Cuotas</h6>
                                                        <p className='m-0'><b>{credito.credito.cantidad_cuotas}</b></p>
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-between  mb-1'>
                                                        <h6 className="card-subtitle m-0 text-body-secondary">Dias mora</h6>
                                                        <p className='m-0'><b>{credito.credito.dias_mora}</b></p>
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-between  mb-1'>
                                                        <h6 className="card-subtitle m-0 text-body-secondary">Cuota Mensual</h6>
                                                        <p className='m-0'><b>${formatearMonedaValue(credito.credito.cuota_mensual)}</b></p>
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-between  mb-1'>
                                                        <h6 className="card-subtitle m-0 text-body-secondary">Valor Credito</h6>
                                                        <p className='m-0'><b>${formatearMonedaValue(credito.credito.valor)}</b></p>
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-between  mb-1'>
                                                        <h6 className="card-subtitle m-0 text-body-secondary">Utilidad</h6>
                                                        <p className='m-0'><b>${formatearMonedaValue(credito.credito.utilidad)}</b></p>
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-between mb-1'>
                                                        <h6 className="card-subtitle m-0 text-body-secondary">Saldo Actual</h6>
                                                        <p className='m-0'><b>${formatearMonedaValue(credito.credito.saldo_actual)}</b></p>
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-between mb-1'>
                                                        <h6 className="card-subtitle m-0 text-body-secondary">Estado</h6>
                                                        {credito.credito.estado_id === 4 ?
                                                            <span className="badge text-bg-success">{credito.credito.estado}</span>
                                                            : credito.credito.estado_id === 3 || credito.credito.estado_id === 13 ?
                                                            <span className="badge text-bg-danger">{credito.credito.estado}</span>
                                                            :
                                                            <span className="badge text-bg-warning">{credito.credito.estado}</span>
                                                        }
                                                    </div>                                      
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-7'>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className='d-flex align-items-center'>
                                                        <i className="bi bi-person-lines-fill mx-2"></i>
                                                        <h6 className="card-subtitle  text-body-secondary  m-0">Informacion del cliente</h6>
                                                    </div>
                                                    <hr></hr>
                                                    <div className='d-flex align-items-center justify-content-between  m-0'>
                                                        <h6 className="card-subtitle text-body-secondary m-0 px-1">Codigo</h6>
                                                        <p className='m-0'><b>{credito.cliente.id_cliente} - {credito.cliente.numero_documento}</b></p>
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-between  m-0'>
                                                        <h6 className="card-subtitle text-body-secondary m-0 px-1">Nombre</h6>
                                                        <p className='m-0'><b>{credito.cliente.nombre} {credito.cliente.apellido}</b></p>
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-between  m-0'>
                                                        <h6 className="card-subtitle text-body-secondary m-0 px-1">Telefono</h6>
                                                        <p className='m-0'><b>{credito.cliente.telefono_1} - {credito.cliente.telefono_2}</b></p>
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-between m-0'>
                                                        <h6 className="card-subtitle text-body-secondary m-0 px-1">Correo</h6>
                                                        <p className='m-0'><b>{credito.cliente.email}</b></p>
                                                    </div>                                        
                                                </div>
                                            </div>
                                            <br></br>
                                            <button 
                                                type="button" 
                                                className="btn btn-primary btn-lg mt-3" 
                                                data-bs-target="#modalRealizarPago" 
                                                data-bs-toggle="modal"
                                                disabled={credito.credito.estado_id === 4 || credito.credito.estado_id === 13}
                                                
                                            >
                                                Registrar un pago
                                            </button>
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-md-12'>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className='d-flex align-items-center justify-content-start'>
                                                        <i className="bi bi-card-checklist mx-2"></i>
                                                        <h6 className="card-subtitle text-body-secondary m-0">Movimientos</h6>                                            
                                                    </div>
                                                    <div className='scroll-tabla'>
                                                        <div className="table-responsive">
                                                            <table className="table table-hover table-sm text-center" style={{fontSize:'12px'}}>
                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th className='text-start'>Transacción</th>
                                                                        <th>Fecha</th>
                                                                        <th>Fecha Pago</th>
                                                                        <th className='text-start'>Valor</th>
                                                                        <th className='text-start'>Interes</th>
                                                                        <th className='text-start'>Capital</th>
                                                                        <th className='text-start'>Saldo</th>
                                                                        <th>Estado</th>
                                                                        <th>Usuario</th>
                                                                        <th>Banco</th>
                                                                        <th>Cuenta</th>
                                                                        <th>Observación</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {Array.isArray(credito.movimientos) ? credito.movimientos.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td className='text-primary text-start'>
                                                                            <b>{item.id_transaccion} - {item.tipo_transaccion}</b>
                                                                        </td>
                                                                        <td>
                                                                            <span className="badge text-bg-dark">{item.fecha_registro}</span>
                                                                        </td>
                                                                        <td>
                                                                            <span className="badge text-bg-primary">{item.fecha_pago}</span>
                                                                        </td>
                                                                        <td className='text-start'>
                                                                            <b>${formatearMonedaValue(item.valor)}</b>
                                                                        </td>
                                                                        <td className='text-success text-start'>
                                                                            <b>${formatearMonedaValue(item.interes)}</b>
                                                                        </td>
                                                                        <td className='text-secondary text-start'>
                                                                            <b>${formatearMonedaValue(item.capital)}</b>
                                                                        </td>
                                                                        <td className='text-danger text-start'>
                                                                            <b>${formatearMonedaValue(item.saldo_credito)}</b>
                                                                        </td>
                                                                        <td>
                                                                        {item.estado_id === 4 || item.estado_id === 11 || item.estado_id === 12 || item.estado_id === 2 ?
                                                                            <span className="badge text-bg-success">{item.estado}</span>
                                                                            : item.estado_id === 3 || item.estado_id === 13 ?
                                                                            <span className="badge text-bg-danger">{item.estado}</span>
                                                                            :
                                                                            <span className="badge text-bg-warning">{item.estado}</span>
                                                                        }
                                                                        </td>
                                                                        <td>
                                                                            {item.usuario}
                                                                        </td>
                                                                        <td>
                                                                            {item.banco}
                                                                        </td>
                                                                        <td>
                                                                            {item.cuenta_ingreso}
                                                                        </td>
                                                                        <td>
                                                                            {item.observacion}
                                                                        </td>
                                                                    </tr>
                                                                )) : <tr></tr>}
                                                                </tbody>
                                                            </table>
                                                            { Array.isArray(credito.movimientos) && credito.movimientos.length == 0 ?
                                                                <div className="d-flex flex-column justify-content-center align-items-center text-center text-secondary" style={{height: '30dvh'}}>
                                                                    <i className="bi bi-card-checklist fs-1"></i>
                                                                    <br></br>
                                                                    <h3>No se encontraron movimientos para el código de crédito <b>{props.idCredito}</b></h3>
                                                                </div>
                                                            :''}
                                                        </div>  
                                                    </div>                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            :''}
                            {noFoundCredito ?
                            <div className="d-flex flex-column justify-content-center align-items-center text-center text-secondary my-4" style={{height: '30dvh'}}>
                                <i className="bi bi-search fs-1"></i>
                                <br></br>
                                <h3>No se encontró el código de crédito <b>{props.idCredito}</b></h3>
                                <br></br>
                                <p className='text-success'><b>Por favor, recargue la pagina e intente nuevamente.</b></p>
                            </div>:''}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapSateToProps = state => {

    return {
        idCredito: state.idCredito
    }
}

const mapDispatchToProps  = {
    setIdCredito
};

export default connect(mapSateToProps, mapDispatchToProps)(ModalPagar)
