import React,{ useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { get_data_anular, anular_credito } from "../../services/Credito"
import { toast, formatearMonedaValue, cerrarModal, alerta } from "../../services/utilities";
import Swal  from 'sweetalert2';

function ModalAnularCredito(props) {

    useEffect(() => {
        setSpiner(true);
        setHayData(false);
        setNoFoundCredito(false)
        handleConsultarDataAnular(true);
    }, [props.idCredito]);

    const [spiner, setSpiner] = useState(true);
    const [hayData, setHayData] = useState(false);
    const [noFoundCredito, setNoFoundCredito] = useState(false);
    const [data, setData] = useState({
        "palabra_seguridad": "",
        "saldos_credito": {
            "capital_pagado": 0,
            "interes_pagado": 0,
            "saldo_actual": 0
        }
    });
    const [form, setForm] = useState({
        "id_credito": props.idCredito,
        "palabra_seguridad": "",
        "observacion": ""
    });     

    const handleConsultarDataAnular= async () => {

        try {
            await get_data_anular(props.idCredito).then(response => {
                if(response.status === 200 && response.data.status_message == 'OK'){
                    setHayData(true);
                    setData(response.data);
                }else{
                    setNoFoundCredito(true)
                }
            });
         }catch (error) {
            const errorMessage = error.message || 'Error desconocido';
            //setMsg('Error al cargar la información del crédito', `Por favor intente nuevamente. Detalles del error: ${errorMessage}`);
        }

        setSpiner(false);
    }

    const handleInputChange = (event) => {

        const { name, value, type } = event.target;
        setForm({ ...form, [name]: value });
        
    };
    
    const handleConfirmarAnulacion = () => {

        if(form.palabra_seguridad !== data.palabra_seguridad ){
            toast('Palabra de seguridad','La palabra de seguirdad no es correcta.', true);
            return;
        }

        if(form.observacion == '' || form.observacion.length < 4){
            toast('* Campos requeridos','Debe ingresar una observación, mínimo 4 caracteres', true);
            return;
        }

        Swal.fire({
            html: `
                    <p><b>Por favor, verifique la información del crédito.</b></p>
                    <hr></hr>
                    <div class='d-flex align-items-center justify-content-between  m-0'>
                        <h6 class="card-subtitle mb-2 text-body-secondary">Codigo del credito</h6>
                        <p class='m-0'><b>${props.idCredito}</b></p>
                    </div>
                    <div class='d-flex align-items-center justify-content-between  m-0'>
                        <h6 class="card-subtitle mb-2 text-body-secondary">Observación</h6>
                        <p class='m-0'><b>${form.observacion}</b></p>
                    </div>                   
                    <hr>
                    <p class="text-secondary" >Si está seguro, presione el botón 'Anular'. De lo contrario, presione 'Cancelar'.</p>
            `,
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            cancelButtonColor: '#6c757d',
            confirmButtonText: 'Si, Anular',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                handleAnularCredito();
            }
        });
    }

    const handleAnularCredito = async () => {

        setSpiner(true);

        await anular_credito(form).then(response => {

            if(response.status === 200 && response.data.status_message == 'OK'){

                cerrarModal('modalAnularCredito');
        
                Swal.fire({
                    title: 'Crédito anulado trx ('+response.data.id_transaccion+')',
                    text:  'Para consultar el estado del crédito, por favor, diríjase a la pestaña "Créditos".',
                    icon: "success",
                    confirmButtonColor: '#198754',
                    confirmButtonText: 'Aceptar',
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.href = '/creditos';
                    }
                });

            }else{
                alerta(response.data.status_message, response.data.error_message)
            }
        }).catch(error =>{

            Swal.fire({
                title: 'Error no controlado al crear el credito',
                text:  error.message+' ES POSIBLE QUE EL CREDITO SE HAYA CREADO, POR FAVOR CONFIRME EN EL MODULO DE CREDITOS.',
                icon: "error",
                confirmButtonColor: '#dc3545',
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = '/creditos';
                }
            });
        })

        setSpiner(false);

    }

    return(

        <React.Fragment>
            <div className="modal fade" id="modalAnularCredito" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                    <div className="modal-content">
                        <div className="modal-header bg-danger">
                        <h5 className="m-0 text-white">Anular credito {props.idCredito} </h5>                         
                        </div>
                        <div className="modal-body">
                        {spiner ?
                            <div className="d-flex align-items-center mb-3">
                                <strong role="status" className='text-secondary'>Cargando Informacion...</strong>
                                <div className="spinner-border ms-auto text-secondary" aria-hidden="true"></div>
                            </div>
                            :''}
                            { hayData ?
                                <>
                                    <div className='row row-saldos-inicio'>
                                        <div className='col-md-4 text-center col-saldo-inicio'>
                                            <h5 className='text-secondary m-0'>Capital Pagado</h5>
                                            <h2 className='m-0 text-success'><b>${formatearMonedaValue(data.saldos_credito.capital_pagado)}</b></h2>                                            
                                        </div>
                                        <div className='col-md-4 text-center col-saldo-inicio'>
                                            <h5 className='text-secondary m-0'>Interes Pagado</h5>
                                            <h2 className='m-0 text-success'><b>${formatearMonedaValue(data.saldos_credito.interes_pagado)}</b></h2>                                            
                                        </div>
                                        <div className='col-md-4 text-center'>
                                            <h5 className='text-secondary m-0'>Saldo Actual</h5>
                                            <h2 className='m-0 text-success'><b>${formatearMonedaValue(data.saldos_credito.saldo_actual)}</b></h2>                                            
                                        </div>
                                    </div>
                                    <p className='text-center'>Para anular el crédito, por favor ingrese la palabra de seguridad "<span className='text-danger'>{data.palabra_seguridad}</span>".</p>
                                    <div className='row justify-content-center '>
                                        <div className='col-md-4'>
                                            <input 
                                                type="text" 
                                                className="form-control form-control-lg" 
                                                placeholder='Ingrese palabra'
                                                id="palabra_seguridad" 
                                                name="palabra_seguridad"
                                                value={form.palabra_seguridad}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className='row justify-content-center mt-4 '>
                                        <div className='col-md-7'>                                        
                                        <textarea 
                                            type="text" 
                                            className="form-control form-control-lg" 
                                            placeholder='Observación'
                                            id="observacion" 
                                            name="observacion" 
                                            value={form.observacion}
                                            onChange={handleInputChange} 
                                        />
                                    </div>
                                    </div>
                                </>
                            :''}
                            {noFoundCredito ?
                            <div className="d-flex flex-column justify-content-center align-items-center text-center text-secondary my-4" style={{height: '30dvh'}}>
                                <i className="bi bi-search fs-1"></i>
                                <br></br>
                                <h3>No se encontró el código de crédito <b>{props.idCredito}</b></h3>
                                <br></br>
                                <p className='text-success'><b>Por favor, recargue la pagina e intente nuevamente.</b></p>
                            </div>:''}
                            
                        </div>
                        <div className="modal-footer">
                            <button 
                                className="btn btn-secondary btn-lg" 
                                data-bs-dismiss="modal"
                                >Cancelar
                            </button>
                            <button 
                                className="btn btn-danger btn-lg"
                                onClick={handleConfirmarAnulacion}
                                >Anular credito
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

}

const mapSateToProps = state => {

    return {
        idCredito: state.idCredito
    }
}

export default connect(mapSateToProps, null)(ModalAnularCredito)