import { setIdCredito } from "../actions";

export const reducer = (state, action) => {
    switch(action.type){
        case "SET_DATA_CLIENTES":
            return {
                ...state,
                dataClientes: action.payload
            }
        case "SET_SALDOS_EMPRESA":
            return {
                ...state,
                saldosEmpresa: action.payload
            }
        case "SET_CREDITO":
            return {
                ...state,
                credito: action.payload
            }
        case "SET_CLIENTE":
            return {
                ...state,
                cliente: action.payload
            }
        case "SET_ID_CREDITO":
            return {
                ...state,
                idCredito: action.payload
            }
        case "SET_USUARIO":
            return {
                ...state,
                usuario: action.payload
            }
        case "SET_USUARIO_EDITAR":
            return {
                ...state,
                usuario_editar: action.payload
            }
        default:
            return state
    }
};