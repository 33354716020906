import InstaceAxios  from "./Api";

export const habilitar_saldo_disponible = async (data) => {
    const result = await InstaceAxios.post('ajustes/habilitar_saldo_disponible', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const traslado_saldo_intereses = async (data) => {
    const result = await InstaceAxios.post('ajustes/traslado_saldo_intereses', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const retirar_saldo = async (data) => {
    const result = await InstaceAxios.post('ajustes/retirar_saldo', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const crear_medio_pago = async (data) => {
    const result = await InstaceAxios.post('ajustes/crear_medio_pago', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const editar_medio_pago = async (data) => {
    const result = await InstaceAxios.post('ajustes/editar_medio_pago', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const eliminar_medio_pago = async (id_empresa_medio_pago = '') => {
    const result = await InstaceAxios.get('ajustes/eliminar_medio_pago?id_empresa_medio_pago='+id_empresa_medio_pago)
        .then(res => res)
        .catch(error => error)
    return result
}

export const generar_codigo_activacion = async () => {
    const result = await InstaceAxios.get('ajustes/generar_codigo_activacion')
        .then(res => res)
        .catch(error => error)
    return result
}

export const codigos_activos = async () => {
    const result = await InstaceAxios.get('ajustes/codigos_activos')
        .then(res => res)
        .catch(error => error)
    return result
}

export const lista_medios_pago = async () => {
    const result = await InstaceAxios.get('ajustes/lista_medios_pago')
        .then(res => res)
        .catch(error => error)
    return result
}

export const tasas_interes_empresa = async () => {
    const result = await InstaceAxios.get('ajustes/tasas_interes_empresa')
        .then(res => res)
        .catch(error => error)
    return result
}

export const tasa_interes_empresa = async (id_empresa_tasa_tipo_credito) => {
    const result = await InstaceAxios.get('ajustes/tasa_interes_empresa?id_empresa_tasa_tipo_credito='+id_empresa_tasa_tipo_credito)
        .then(res => res)
        .catch(error => error)
    return result
}


export const editar_tasa_interes_empresa = async (data) => {
    const result = await InstaceAxios.post('ajustes/editar_tasa_interes_empresa', data)
        .then(res => res)
        .catch(error => error)
    return result
}

