import InstaceAxios  from "./Api";

export const tabla_amortizacion = async (valor=0, cuotas=0, interes=0, tipo_credito=null) => {

    const result = await InstaceAxios.get('credito/tabla_amortizacion?valor='+valor+'&cuotas='+cuotas+'&interes='+interes+'&tipo_credito='+tipo_credito)
        .then(res => res)
        .catch(error => error)
    return result
}

export const crear_credito = async (data) => {
    const result = await InstaceAxios.post('credito/crear', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const creditos = async (fechas=false, fecha_ini=false, fecha_fin=false, estado_id=false) => {

    const result = await InstaceAxios.get('credito/creditos?fechas='+fechas+'&fecha_ini='+fecha_ini+'&fecha_fin='+fecha_fin+'&estado_id='+estado_id)
        .then(res => res)
        .catch(error => error)
    return result
}

export const get_credito = async (id_credito=0) => {
    const result = await InstaceAxios.get('credito/credito?id_credito='+id_credito)
        .then(res => res)
        .catch(error => error)
    return result
}

export const get_valor_pago = async (id_credito=0, tipo_pago=0, fecha_pago='') => {
    const result = await InstaceAxios.get('credito/valor/pago?id_credito='+id_credito+'&tipo_pago='+tipo_pago+'&fecha_pago='+fecha_pago)
        .then(res => res)
        .catch(error => error)
    return result
}

export const get_data_anular = async (id_credito=0) => {
    const result = await InstaceAxios.get('credito/data/anular?id_credito='+id_credito)
        .then(res => res)
        .catch(error => error)
    return result
}

export const anular_credito = async (data) => {
    const result = await InstaceAxios.post('credito/anular', data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const get_token = async (id_credito=0) => {
    const result = await InstaceAxios.get('credito/token?id_credito='+id_credito)
        .then(res => res)
        .catch(error => error)
    return result
}

export const solicitudes = async () => {

    const result = await InstaceAxios.get('credito/solicitudes')
        .then(res => res)
        .catch(error => error)
    return result
}

export const get_count = async () => {

    const result = await InstaceAxios.get('credito/solicitudes/count')
        .then(res => res)
        .catch(error => error)
    return result
}