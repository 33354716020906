import InstaceAxios  from "./Api";

export const login = async (data) => {
    const result = await InstaceAxios.post(`usuario/login`, data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const get_saldos_empresa = async () => {
    const result = await InstaceAxios.get('usuario/saldos/empresa')
        .then(res => res)
        .catch(error => error)
    return result
}

export const get_bancos = async () => {
    const result = await InstaceAxios.get('usuario/bancos')
        .then(res => res)
        .catch(error => error)
    return result
}

export const get_usuario = async () => {
    const result = await InstaceAxios.get('usuario/usuario')
        .then(res => res)
        .catch(error => error)
    return result
}

export const get_usuarios = async () => {
    const result = await InstaceAxios.get('usuario/usuarios')
        .then(res => res)
        .catch(error => error)
    return result
}

export const validarSegundaClave = async (segunda_clave) => {
    const result = await InstaceAxios.get(`usuario/validar_segunda_clave?segunda_clave=${segunda_clave}`)
        .then(res => res)
        .catch(error => error)
    return result
}

export const cambiar_password = async (data) => {
    const result = await InstaceAxios.post(`usuario/cambiar_password`, data)
        .then(res => res)
        .catch(error => error)
    return result
}

export const crear_usuario = async (data) => {
    const result = await InstaceAxios.post(`usuario/crear`, data)
        .then(res => res)
        .catch(error => error)
    return result
}


export const editar_usuario = async (data) => {
    const result = await InstaceAxios.post(`usuario/editar`, data)
        .then(res => res)
        .catch(error => error)
    return result
}
