import InstaceAxios  from "./Api";

export const extracto = async (fecha_ini=false, fecha_fin=false, tipo_saldo=false) => {

    const result = await InstaceAxios.get('reportes/extracto?fecha_ini='+fecha_ini+'&fecha_fin='+fecha_fin+'&tipo_saldo='+tipo_saldo)
        .then(res => res)
        .catch(error => error)
    return result
}

export const get_graficos = async () => {

    const result = await InstaceAxios.get('reportes/graficos_inicio')
        .then(res => res)
        .catch(error => error)
    return result
}