import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import  avatar_gril from  "../../assetss/img/avatar_gril.png"
import  avatar_men from  "../../assetss/img/avatar_men.png"
import ViweClient from './ViweClient';
import ModalSolicitudesCredito from './ModalSolicitudesCredito';

function DataTableClient(props) {

    const [filter, setFilter] = useState('');
    const [idCliente, setidCliente] = useState(false);

    const filteredData = props.dataClientes.filter((item) => {
        const nombre = item.nombre.toLowerCase().includes(filter.toLowerCase());
        const apellido = item.apellido.toLowerCase().includes(filter.toLowerCase());
        const numero_documento = item.numero_documento.toLowerCase().includes(filter.toLowerCase());
        return nombre || apellido || numero_documento;
    });

    return (
        <React.Fragment>
            
            <div className='row mb-3'>

                <div className='col-md-4 mt-2'>
                    <div className="input-group">
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Buscar..."  
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)} 
                        />
                    </div>
                </div>
                <div className='col-md-4 mt-2'>
                    <button 
                        className="btn btn-success" 
                        type="button" 
                        data-bs-toggle="modal" 
                        data-bs-target="#ModalFormDataClient"
                        >
                        Nuevo Prospecto
                    </button>
                </div>

            </div>

            { filteredData.length > 0 ?
            <>
                <div className={`d-flex align-items-center ${props.loading}`}>
                    <strong>Por favor espere...</strong>
                    <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                </div>
                <div className="table-responsive">
                    <table className="table table-hover table-sm text-start" style={{fontSize:'12px'}}>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Prospecto</th>
                                <th>Documento</th>
                                <th>Email</th>
                                <th>Telefono</th>
                                <th>Fecha de actulización</th>
                                <th>Estado</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map((cliente, index) => (
                                <tr key={index} className={cliente.solicitudes.length > 0 ? 'align-middle table-success' : 'align-middle'}>
                                    <td>{cliente.id_cliente}</td>
                                    <td>
                                        <img width="25" className="rounded-circle mx-2" src={cliente.sexo == 'M'? avatar_men : avatar_gril } alt="" />
                                        {cliente.nombre} {cliente.apellido}
                                    </td>
                                    <td>{cliente.tipo_documento} {cliente.numero_documento}</td>
                                    <td>{cliente.email}</td>
                                    <td>{cliente.telefono_1}</td>
                                    <td>{cliente.fecha_actulizacion}</td>
                                    <td>{cliente.estado_id === 1 ? <span className="badge text-bg-success">Activo</span> : <span className="badge text-bg-danger">Inactivo</span>}
                                    </td>  
                                    <td>
                                        <button 
                                            data-bs-toggle="modal" 
                                            data-bs-target="#ModalViweClient" 
                                            className="btn btn-warning btn-sm" 
                                            title="Ver informacion del cliente"
                                            onClick={() => setidCliente(cliente.id_cliente)}
                                        >
                                            Editar
                                        </button>
                                    </td>                     
                                    <td>
                                        {cliente.solicitudes.length > 0 ?
                                        <button 
                                            className='btn btn-outline-dark btn-sm'
                                            data-bs-toggle="modal" 
                                            data-bs-target="#ModalSolicitudesCredito"
                                            title="Ver informacion del cliente"
                                            onClick={() => setidCliente(cliente.id_cliente)}
                                        >
                                            {cliente.solicitudes.length+ ' solicitudes'}
                                        </button>
                                        :''}
                                    </td>                                      
                                    
                                </tr>
                            ))}
                        </tbody>
                    </table>   
                </div>           
            </>:''}  
            { filteredData.length == 0 ?
                <div className='text-center text-secondary my-4  align-items-center'>
                    <h3>Sin resultado de búsqueda.</h3>
                    <p>Por favor, aplique otro filtro de búsqueda.</p>
                </div>
            :''}
            <ViweClient idCliente={idCliente}/>
            <ModalSolicitudesCredito  idCliente={idCliente}></ModalSolicitudesCredito>
        </React.Fragment>
    );
  }

  const mapSateToProps = state => {

    return {
        dataClientes: state.dataClientes
    }

  }
  
  export default  connect(mapSateToProps, null)(DataTableClient);