import React from 'react';
import Sidebar from '../Header/Sidebar';
import HabilitarSaldo from '../Inicio/HabilitarSaldo';
import TrasladoSaldo from '../Inicio/TrasladoSaldo';
import RetirarSaldo from '../Inicio/RetirarSaldo';
function Ajustes() {

    return(

        <React.Fragment>
            <div className='container container-modulos'>

                <div className='row'>
                    <div className='col-md-5'>
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Saldos</h5>
                                <hr></hr>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className="alert alert-success card-option" role="alert" data-bs-toggle="modal" data-bs-target="#ModalHabilitarSaldo">
                                            <div className='d-flex align-items-center'>
                                                <i className="bi bi-coin" style={{fontSize: '24px'}}></i>
                                                <h4 className='my-0 mx-2'>Habilitar saldo disponible</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className="alert alert-primary card-option" role="alert" data-bs-toggle="modal" data-bs-target="#ModalTrasladoSaldo">
                                            <div className='d-flex align-items-center'>
                                                <i className="bi bi-repeat" style={{fontSize: '24px'}}></i>
                                                <h4 className='my-0 mx-2'>Trasladar saldo desde intereses</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className="alert alert-danger card-option" role="alert" data-bs-toggle="modal" data-bs-target="#ModalRetirarSaldo">
                                            <div className='d-flex align-items-center'>
                                                <i className="bi bi-building-down" style={{fontSize: '24px'}}></i>
                                                <h4 className='my-0 mx-2'>Retirar saldo</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </React.Fragment>
    );

}

export default Ajustes