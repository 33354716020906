import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setSaldosEmpresa } from '../../actions'
import { get_saldos_empresa } from "../../services/Login"
import { toast, formatearMonedaValue } from "../../services/utilities";

const Saldos = (props) => {

    useEffect(() => {
        handleConsultarSaldosEmpresa()
    }, []);

    const handleConsultarSaldosEmpresa = async () => {

        get_saldos_empresa().then(response => {
            if(response.status === 200){
                console.log(response.data.data)
                props.setSaldosEmpresa(response.data.data)
            }else{
                toast('Error al cargar los saldos', response.message)
            }
        }).catch(error =>{
            toast('Error al cargar los saldos', error.message) 
        }). finally(
            
        )

    }

    return (
        <div className="row">
            <div className="text-center col-md-4">
                <p className="text-white m-0"><b>Disponible</b></p>
                <span className="badge text-bg-success fs-6" style={{ width :'120px'}}>${formatearMonedaValue(props.saldosEmpresa.disponible)}</span>
            </div>
    
            <div className="text-center col-md-4">
                <p className="text-white m-0"><b>Prestado</b></p>
                <span className="badge text-bg-secondary fs-6" style={{ width :'120px'}}>${formatearMonedaValue(props.saldosEmpresa.prestado)}</span>
            </div>
        
            <div className="text-center col-md-4">
                <p className="text-white m-0"><b>Intereses</b></p>
                <span className="badge text-bg-primary fs-6" style={{ width :'120px'}}>${formatearMonedaValue(props.saldosEmpresa.intereses)}</span>
            </div>
        </div>
    );

}

const mapSateToProps = state => {

    return {
        saldosEmpresa: state.saldosEmpresa
    }
}

const mapDispatchToProps  = {
    setSaldosEmpresa,
};

export default connect(mapSateToProps, mapDispatchToProps)(Saldos)