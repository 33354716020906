import React from "react";

const NotFound = () => (
    <React.Fragment>
        <div className='text-center text-secondary my-4 align-items-center'>
            <h1>404</h1>
            <h3>Página no encontrada</h3>
            <p>Por favor, selecciona una opción del menú superior.</p>
        </div>
    </React.Fragment>
);

export default NotFound