import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { validateEmail } from "../services/validate";
import { login }  from "../services/Login";
import Logo from '../assetss/img/logospace.png'
import imgrigth from '../assetss/img/work.gif'

function  Login (props){

    useEffect(() => {
        localStorage.clear();
    }, []);

    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [disabled_botton, setDsiabledBotton] = useState(false);
    const [form, setValue] = useState({
        usuario:'',
        password:''
    });

    const handleInput = event => {
        setValue({
            ...form,
            [event.target.name]: event.target.value
        })
    };

    const handleLogin = () => {

        if(form.password.length < 1 ){
            setError(true)
            setErrorMessage('Por favor digite una contraseña');
            return;
        }else{
            setError(false);
        }

        setDsiabledBotton(true)

        login(form).then(response => {
            
            if(response.status === 200){
                setError(false)
                setErrorMessage('')
                localStorage.setItem("token", response.data.jwt)
                window.location.href = '/inicio';
            }else{
                setError(true)
                if( response.response.data){
                    setErrorMessage(response.response.data.status_message);
                }else{
                    setErrorMessage(response.message);
                }
            }

            setDsiabledBotton(false)
        })
    
    }

    return (
        <React.Fragment>
            <section className="vh-100 section-login">
                <div className="container py-4 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col col-xl-10">
                            <div className="div-card-login">
                                <div className="row">
                                    <div className="col-md-6 col-lg-5 div-img-login">
                                        <img src={imgrigth} alt="login form" className="img-fluid img-login" />
                                    </div>
                                    <div className="col-md-6 col-lg-7 d-flex align-items-center">
                                        <div className="card-body p-4 p-lg-5 text-black card-body-form">
                                            <form>
                                                <p className="login-title">Iniciar sesión en su cuenta</p>
                                                <div className="form-floating mb-3">
                                                    <input 
                                                        type="usuario" 
                                                        id="usuario" 
                                                        name="usuario" 
                                                        placeholder="Usuario"
                                                        className="form-control form-control-lg" 
                                                        value={form.usuario} 
                                                        onChange={handleInput} 
                                                    />
                                                    <label className="form-label" htmlFor="usuario">Usuario</label>
                                                </div>
                                                <div className="form-floating mb-3">
                                                    <input 
                                                        type="password" 
                                                        id="password" 
                                                        name="password" 
                                                        className="form-control form-control-lg" 
                                                        placeholder="Password" 
                                                        value={form.password} 
                                                        onChange={handleInput} 
                                                    />
                                                    <label className="form-label" htmlFor="password">Contraseña</label>
                                                </div>
                                                <div className="pt-1 mb-4 d-flex align-items-center ">
                                                    <button 
                                                        className="btn btn-outline-primary btn-lg btn-block"
                                                        type="button" 
                                                        disabled={disabled_botton} 
                                                        onClick={handleLogin}>
                                                        Iniciar sesión
                                                    </button>
                                                    {disabled_botton &&
                                                        <div className="spinner-border spinner-login mx-4">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    }
                                                </div>
                                                
                                                {error &&
                                                    <div className="alert alert-danger d-flex align-items-center alert-login" role="alert">
                                                        <i className="bi bi-x-circle pe-2"></i>
                                                        <div>
                                                            {error_message}
                                                        </div>
                                                    </div>
                                                }
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}


export default connect(null, null)(Login)