import React,{ useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { setIdCredito } from '../../actions';

function ModalCodigoCredito(props) {

    const navigate = useNavigate();
    const [filter, setFilter] = useState('');
    

    const handleBuscarIdCredito = async () => {
        props.setIdCredito(filter);
        navigate('/credito');        
    }

    return(

        <React.Fragment>
           <div className="modal fade" id="ModalCodigoCredito" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        <div className="modal-header bg-header-modal">
                            <h5 id="staticBackdropLabel" className='m-0'>Código del credito</h5>
                        </div>
                        <div className="modal-body">
                            <div className="input-group flex-nowrap">
                                <span className="input-group-text" id="addon-wrapping"><i className="bi bi-search"></i></span>
                                <input 
                                    type="number" 
                                    className="form-control form-control-lg" 
                                    placeholder="Código"
                                    name="id_credito"
                                    id="id_credito"
                                    value={filter}
                                    onChange={(e) => setFilter(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                            <button type="button" className="btn btn-primary" disabled={filter === ''} data-bs-dismiss="modal" onClick={handleBuscarIdCredito} >Buscar</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapDispatchToProps  = {
    setIdCredito
};

export default connect(null, mapDispatchToProps)(ModalCodigoCredito)
