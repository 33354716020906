import React, {useState, useEffect} from 'react';
import { alerta, formatearMoneda } from "../../services/utilities";
import { validateEmail } from "../../services/validate";
import swal from 'sweetalert';
import { crear_cliente, tipo_activo, bancos, municipios } from "../../services/Client"
import { cerrarModal} from "../../services/utilities"

function ModalNewClient() {

    const [lista_tipo_activo, setListaTipoActivo] = useState([]);
    const [lista_bancos, setBancos] = useState([]);
    const [lista_municipios, setMunicipios] = useState([]);
    const [count_tab, setCountTab] = useState(1);
    const [class_tips, setClassTips] = useState('container text-center');
    const [class_form1, setClassForm1] = useState('container d-none');
    const [class_form2, setClassForm2] = useState('container d-none');
    const [class_form3, setClassForm3] = useState('container d-none');
    const [class_form4, setClassForm4] = useState('container d-none');
    const [class_form5, setClassForm5] = useState('container d-none');
    const [class_form6, setClassForm6] = useState('container d-none');
    const [btn_atras,  setBtnAtras] = useState('d-none btn btn-secondary');
    const [btn_siguiente, setBtnSiguiente] = useState('btn btn-success');
    const [btn_guardar,  setBtnGuardar] = useState('btn btn-primary d-none');
    const [btn_add_reference, setBtnAddReference] = useState(false);
    const [btn_add_propertie, setBtnAddPropertie] = useState(false);
    const [captionTableAddReference, setcaptionTableAddReference] = useState('')
    const [captionTableAddPropertie, setcaptionTableAddPropertie] = useState('')
    const [list_reference, setListReferences] = useState();
    const [list_porpertie, setListPropertie] = useState();

    useEffect(() => {
        tipo_activo().then(response => {
            if(response.status === 200){
                setListaTipoActivo(response.data.data);
            }else{
                alerta('Error al cargar la lista de tipos de propiedades')
            }
        })

        bancos().then(response => {
            if(response.status === 200){
                setBancos(response.data.data);
            }else{
                alerta('Error al cargar la lista de bancos')
            }
        })
        municipios().then(response => {
            if(response.status === 200){
                setMunicipios(response.data.data);
            }else{
                alerta('Error al cargar la lista de municipios')
            }
        })
    }, []);
  
    const [cliente, setCliente] = useState({
            "nombre":"",
            "apellido":"",
            "tipo_documento":"CC",
            "numero_documento":"",
            "fecha_expedicion_documento":"",
            "lugar_expedicion_documento":"",
            "fecha_nacimiento":"",
            "lugar_nacimiento":"",
            "sexo":"M",
            "estado_civil":"SOLTERO",
            "profesion":"",
            "telefono_1":"",
            "telefono_2":"",
            "email":"",
            "direccion":"",
            "barrio":"",
            "ingresos_fijos":"",
            "otros_ingresos":"",
            "gastos_fijos":"",
            "deudas":"",
            "numero_cuenta":"",
            "banco_id":"",
            "tipo_cuenta":"AHORROS",
            "municipio_id": "",
            "activo_cliente":[],
            "referencia_cliente":[]
        }
    );

    const [activo_cliente, setActivoCliente] = useState({
            "descripcion":"",
            "valor": 0,
            "estado_activo":"USADO",
            "tipo_activo_id": ""
        }
    );

    const [referencia_cliente, setReferenciaCliente] = useState({
            "nombre":"",
            "apellido":"",
            "telefono":"",
            "tipo":"PERSONAL"
        }
    );

    const handleFormatMoney = (event) => {
        /*formatearMoneda(event.target)
        const { name, value } = event.target;
        setClient({ ...client, [name]: value });*/
    }

    const handleInputChange = (event) => {

        const { name, value } = event.target;
        setCliente({ ...cliente, [name]: value });
    };

    const handleInputReferenceChange = (event) => {
        const { name, value } = event.target;
        setReferenciaCliente({ ...referencia_cliente, [name]: value });
    };

    const handleInputPropertiesChange = (event) => {
        const { name, value } = event.target;
        setActivoCliente({ ...activo_cliente, [name]: value });
    };

    const handleAddReference = () => {
        
        if(referencia_cliente.nombre.length < 3){
            alerta('Debe ingresar el nombre','')
            return
        }else if(referencia_cliente.apellido.length < 3){
            alerta('Debe ingresar al menos un apellido','')
            return
        }else if(referencia_cliente.telefono.length < 10){
            alerta('Celular Invalido','')
            return
        }

        setcaptionTableAddReference('d-none')

        if(Array.isArray(cliente.referencia_cliente)){
            cliente.referencia_cliente.push(referencia_cliente)
            setListReferences(cliente.referencia_cliente.map((r, indice) => (

                <tr key={indice} id={'tr-reference-'+indice}>
                    <td><p>{indice + 1}</p></td>
                    <td><p>{r.nombre}</p></td>
                    <td><p>{r.apellido}</p></td>
                    <td><p>{r.telefono}</p></td>
                    <td><p>{r.tipo}</p></td>
                    <td>
                        <a  href='#' className="text-danger" onClick={ () => handleDelteReference(indice)}>
                            <i className="bi bi-trash"></i>
                        </a>
                    </td>
                </tr>
            )))
        }

        setReferenciaCliente({
            "nombre":"",
            "apellido":"",
            "telefono":"",
            "tipo":"PERSONAL"
        });

        setBtnAddReference(cliente.referencia_cliente.length == 4 ? true : false)
        
    }

    const handleAddPropertie = () => {
        
        setcaptionTableAddPropertie('d-none')

        if(Array.isArray(cliente.activo_cliente)){
            cliente.activo_cliente.push(activo_cliente)
            setListPropertie(cliente.activo_cliente.map((p, indice) => (

                <tr key={indice} id={'tr-propertie-'+indice}>
                    <td><p>{indice + 1}</p></td>
                    <td><p>{p.tipo_activo_id}</p></td>
                    <td><p>{p.descripcion}</p></td>
                    <td><p>{p.valor}</p></td>
                    <td><p>{p.estado_activo}</p></td>
                    <td>
                        <a  href='#' className="text-danger" onClick={ () => handleDeltePropertie(indice)}>
                            <i className="bi bi-trash"></i>
                        </a>
                    </td>
                </tr>
            )))
        }

        setActivoCliente({
            "descripcion":"",
            "valor": 0,
            "estado_activo":"USADO",
            "tipo_activo_id": ""
        })

        setBtnAddPropertie(cliente.activo_cliente.length == 4 ? true : false)
    }

    const handleDelteReference = (indice) => {
        
        let references = cliente.referencia_cliente
        references.splice(indice, 1)
        setListReferences(cliente.referencia_cliente.map((r, indice) => (
            <tr key={indice} id={'tr-reference-'+indice}>
                <td><p>{indice + 1}</p></td>
                <td><p>{r.frist_name}</p></td>
                <td><p>{r.last_name}</p></td>
                <td><p>{r.telephone_number}</p></td>
                <td><p>{r.type_reference}</p></td>
                <td><p>{r.relation}</p></td>
                <td>
                    <a  href='#' className="text-danger" onClick={ () => handleDelteReference(indice)}>
                        <i className="bi bi-trash"></i>
                    </a>
                </td>
            </tr>
        )))

        setBtnAddReference(false)
        setcaptionTableAddReference(cliente.referencia_cliente.length == 0 ? '' : 'd-none')
    }

    const handleDeltePropertie = (indice) =>{
        
        let propertie = cliente.activo_cliente
        propertie.splice(indice, 1)
        setListPropertie(cliente.activo_cliente.map((p, indice) => (
            <tr key={indice} id={'tr-propertie-'+indice}>
                <td><p>{indice + 1}</p></td>
                <td><p>{p.properties_client}</p></td>
                <td><p>{p.properties_client}</p></td>
                <td><p>{p.properties_client}</p></td>
                <td><p>{p.properties_client}</p></td>
                <td><p>{p.properties_client}</p></td>
                <td>
                    <a  href='#' className="text-danger" onClick={ () => handleDeltePropertie(indice)}>
                        <i className="bi bi-trash"></i>
                    </a>
                </td>
            </tr>
        )))

        setBtnAddPropertie(false)
        setcaptionTableAddPropertie(cliente.activo_cliente.length == 0 ? '' : 'd-none')
    }

    const handleTabForm = () => {
        
        console.log(cliente);

        if(count_tab == 1){
            setBtnAtras('btn btn-secondary')
            setClassTips('container text-center d-none')
            setClassForm1('container')
        }else if (count_tab == 2){
            
            if(cliente.numero_documento.length < 5){
                alerta('Documento invalido','')
                return
            }else if(cliente.nombre.length < 3){
                alerta('Nombre invalido','')
                return
            }else if(cliente.apellido.length < 4){
                alerta('Apellidos invalido','')
                return
            }

            setClassForm1('container d-none')
            setClassForm2('container')
        }else if (count_tab == 3){

            if(cliente.telefono_1.length < 10){
                alerta('Telefono invalido','')
                return
            }else if(!validateEmail(cliente.email)){
                alerta('Correo electronico invalido','')
                return
            }else if(cliente.direccion.length < 3){
                alerta('Direccion invalida','')
                return
            }

            setClassTips('container text-center d-none')
            setClassForm2('container d-none')
            setClassForm3('container')
        }else if (count_tab == 4){
            setClassForm3('container d-none')
            setClassForm4('container')
        }else if (count_tab == 5){
            setClassForm4('container d-none')
            setClassForm5('container')
            setBtnAtras('btn btn-secondary')
            setBtnSiguiente('d-none btn btn-success')
            setBtnGuardar('btn btn-primary')
        }else if (count_tab == 6){
            setClassForm5('container d-none')
            setClassForm6('container') 
        }
        setCountTab(count_tab + 1)
    };

    const handleTabFormAtras = () => {

        if(count_tab == 6){
            setClassForm4('container')
            setClassForm5('container d-none')
            setBtnSiguiente('btn btn-success')
            setBtnGuardar('btn btn-primary d-none')
        }else if(count_tab == 5){
            setClassForm3('container')
            setClassForm4('container d-none')
        }else if(count_tab == 4){
            setClassForm2('container')
            setClassForm3('container d-none')
        }else if(count_tab == 3){
            setClassForm1('container')
            setClassForm2('container d-none')
        }else if(count_tab <= 2){
            setClassTips('container text-center')
            setClassForm1('container d-none')
            setBtnAtras('btn btn-secondary d-none')
        }
        setCountTab(count_tab - 1)
    };

    const handleSaveClient = () =>{

        swal({
            title: "¿ Esta seguro de guardar la información del prospecto ?",
            text: "Si esta seguro presione el boton OK de lo contrario Cancelar",
            icon: "info",
            buttons: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                
                crear_cliente(cliente).then(response => {

                    if(response.status == 201){
                        alerta('Prospecto creado de forma exitosa','','success')
                        //Limpia la data del cliente
                        setCliente({
                            "nombre":"",
                            "apellido":"",
                            "tipo_documento":"CC",
                            "numero_documento":"",
                            "fecha_expedicion_documento":"",
                            "lugar_expedicion_documento":"",
                            "fecha_nacimiento":"",
                            "lugar_nacimiento":"",
                            "sexo":"M",
                            "estado_civil":"SOLTERO",
                            "profesion":"",
                            "telefono_1":"",
                            "telefono_2":"",
                            "email":"",
                            "direccion":"",
                            "barrio":"",
                            "ingresos_fijos":"",
                            "otros_ingresos":"",
                            "gastos_fijos":"",
                            "deudas":"",
                            "numero_cuenta":"",
                            "banco_id":"",
                            "tipo_cuenta":"AHORROS",
                            "municipio_id": "",
                            "activo_cliente":[],
                            "referencia_cliente":[]
                        })
                        setCountTab(1)
                        setBtnAtras('btn btn-secondary d-none')
                        setBtnGuardar('btn btn-primary d-none') 
                        setBtnSiguiente('btn btn-success')
                        setClassTips('container text-center')
                        setClassForm5('container d-none')
                        cerrarModal('ModalFormDataClient');

                    }else{
                        alerta(response.response.data.status_message,response.response.data.error_message);
                    }
                })
            }
        });
    }

      return(

        <React.Fragment>
            <div className="modal fade" id="ModalFormDataClient" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-body d-flex justify-content-center align-items-center modal-body-client">
                            <div className='breadcrumb-passed text-secondary '>
                                <p>{count_tab}</p><p>/</p><p>6</p>
                            </div>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close-modal"></button>
                            <form className='formDataclient'>

                                <div className={class_tips}>
                                    <i className="bi bi-lightbulb text-secondary fs-1"></i>
                                    <h4 className='text-secondary'>
                                        A continuación se presentan algunos criterios a tener en cuenta para una mejor experiencia y manejo de la información.
                                    </h4>
                                    <div className="div-tips d-flex justify-content-center pt-2">
                                        <div className="alert alert-primary" role="alert">
                                            <i className="bi bi-person-bounding-box"></i>
                                            Procura registrar todos los campos de la información personal del prospecto.
                                        </div>

                                        <div className="alert alert-success" role="alert">
                                            <i className="bi bi-geo-alt"></i>
                                            La información de contacto es indispensable para poder ubicar al prospecto, no olvides detallar la dirección.
                                        </div>
                                    </div>

                                    <div className="div-tips d-flex justify-content-center pt-2">
                                        <div className="alert alert-info" role="alert">
                                            <i className="bi bi-cash-coin"></i>
                                            Trata de que el prospecto sea lo más acertado posible con la información financiera que te suministre.
                                        </div>

                                        <div className="alert alert-dark " role="alert">
                                            <i className="bi bi-house"></i>
                                            Recuerda registrar las referencias, activos y bienes del prospecto.
                                        </div>
                                    </div>
                                </div>

                                <div className={class_form1}>
                                    <center><i className="bi bi-file-person text-secondary fs-1"></i></center>
                                    <h4 className='text-secondary text-center pb-4'>Información Personal</h4>
                                    <div className='row'>
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="tipo_documento">Tipo Doumento: <span className='text-danger'>*</span></label>
                                            <select className="form-select form-control-md" name="tipo_documento" value={cliente.tipo_documento} onChange={handleInputChange}>
                                                <option value="CC">Cedula</option>
                                                <option value="TI">Tarjeta de Identidad</option>
                                                <option value="P">Pasaporte</option>
                                                <option value="CE">Cedula Extrangeria</option>
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="num_document"># Documento: <span className='text-danger'>*</span></label>
                                            <input type="number" id="num_document_add" name="numero_documento" placeholder="Numero de documento" className="form-control form-control-md" value={cliente.numero_documento} onChange={handleInputChange} required />
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="fecha_expedicion_documento">Fecha de expedicion:</label>
                                            <input type="date" id="fecha_expedicion_documento" name="fecha_expedicion_documento" placeholder="Fecha de expedicion" className="form-control form-control-md" value={cliente.fecha_expedicion_documento}onChange={handleInputChange} />
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="lugar_expedicion_documento">Lugar de expedicion:</label>
                                            <input type="text" id="lugar_expedicion_documento" name="lugar_expedicion_documento" placeholder="Ej: Bogota" className="form-control form-control-md" value={cliente.lugar_expedicion_documento} onChange={handleInputChange} />
                                        </div>
                                    </div>
                                    <br/>
                                    <div className='row'>
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="lugar_nacimiento">Lugar de nacimiento:</label>
                                            <input type="text" id="lugar_nacimiento" name="lugar_nacimiento" placeholder="Ej: Bogota" className="form-control form-control-md" value={cliente.lugar_nacimiento} onChange={handleInputChange} />
                                        </div>                                    
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="fecha_nacimiento">Fecha de nacimiento:</label>
                                            <input type="date" id="fecha_nacimiento" name="fecha_nacimiento" placeholder="Digite los apellidos del cliente" className="form-control form-control-md" value={cliente.fecha_nacimiento} onChange={handleInputChange} />
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="nombre">Nombres: <span className='text-danger'>*</span></label>
                                            <input type="text" id="nombre" name="nombre" placeholder="Digite el nombre del cliente" className="form-control form-control-md" value={cliente.nombre} onChange={handleInputChange} />
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="apellido">Apellidos: <span className='text-danger'>*</span></label>
                                            <input type="text" id="apellido" name="apellido" placeholder="Digite los apellidos del cliente" className="form-control form-control-md" value={cliente.apellido} onChange={handleInputChange} />
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="sexo">Sexo:</label>
                                            <select className="form-select form-control-md" name="sexo" value={cliente.sexo} onChange={handleInputChange}>
                                                <option value="M">MASCULINO</option>
                                                <option value="F">FEMENINO</option>
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="estado_civil">Estado Civil:</label>
                                            <select className="form-select form-control-md" name="estado_civil" value={cliente.estado_civil} onChange={handleInputChange}>
                                                <option value="SOLTERO">SOLTERO</option>
                                                <option value="CASADO">CASADO</option>
                                                <option value="U.LIBRE">U.LIBRE</option>
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="profesion">Profesión:</label>
                                            <input type="text" id="profesion" name="profesion" placeholder="Ej: Abogado" className="form-control form-control-md" value={cliente.profesion} onChange={handleInputChange} />
                                        </div>
                                    </div>
                                </div>

                                <div className={class_form2}>
                                    <center><i className="bi bi-geo-alt-fill text-secondary fs-1"></i></center>
                                    <h4 className='text-secondary text-center pb-4'>Información de contacto</h4>

                                    <div className='row'>
                                        <div className="col-md-6">
                                            <label className="form-label" htmlFor="telefono_1">Telefono: <span className='text-danger'>*</span></label>
                                            <input type="number" id="telefono_1" name="telefono_1" placeholder="Telefono celular" className="form-control form-control-md" maxLength="10" value={cliente.telefono_1} onChange={handleInputChange} />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label" htmlFor="telefono_2">Telefono 2:</label>
                                            <input type="number" id="telefono_2" name="telefono_2" placeholder="Telefono fijo" className="form-control form-control-md" maxLength="10" value={cliente.telefono_2} onChange={handleInputChange} />
                                        </div> 
                                    </div>
                                    <br></br>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="form-label" htmlFor="email">Correo electronico: <span className='text-danger'>*</span></label>
                                            <input type="text" id="email" name="email" placeholder="ejemplo@dominio.com" className="form-control form-control-md" value={cliente.email} onChange={handleInputChange} />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label" htmlFor="municipio_id">Ciudad de residencia: <span className='text-danger'>*</span></label>
                                            <select className="form-select form-control-md" name="municipio_id" value={cliente.municipio_id} onChange={handleInputChange}>
                                                <option value="" disabled> -- Seleccnione -- </option>
                                                {lista_municipios.map((municipio, indice) => (
                                                    <option value={municipio.id_municipio} key={indice}>{municipio.nombre}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="form-label" htmlFor="direccion">Direccion: <span className='text-danger'>*</span></label>
                                            <input type="text" id="direccion" name="direccion" placeholder="Calle 14 Cra 10 " className="form-control form-control-md" value={cliente.direccion} onChange={handleInputChange} />
                                        </div> 
                                        <div className="col-md-6">
                                            <label className="form-label" htmlFor="barrio">Barrio / Verdad / Localidad:</label>
                                            <input type="text" id="barrio" name="barrio" placeholder="Ej: Centro" className="form-control form-control-md" value={cliente.barrio} onChange={handleInputChange} />
                                        </div> 
                                    </div>
                                </div>

                                <div className={class_form3}>
                                    <center><i className="bi bi-cash-coin text-secondary fs-1"></i></center>
                                    <h4 className='text-secondary text-center pb-4'>Información Financiera</h4>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="ingresos_fijos">Ingresos fijos</label>
                                            <input type="number" id="ingresos_fijos" name="ingresos_fijos" placeholder="$1.000.000" className="form-control form-control-md"  value={cliente.ingresos_fijos} onChange={handleInputChange} onBlur={handleFormatMoney} />
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="otros_ingresos">Otros ingresos</label>
                                            <input type="number" id="otros_ingresos" name="otros_ingresos" placeholder="$1.000.000" className="form-control form-control-md"  value={cliente.otros_ingresos} onChange={handleInputChange} onBlur={handleFormatMoney} />
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="gastos_fijos">Gastos fijos</label>
                                            <input type="number" id="gastos_fijos" name="gastos_fijos" placeholder="$1.000.000" className="form-control form-control-md"  value={cliente.gastos_fijos} onChange={handleInputChange} onBlur={handleFormatMoney} />
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="deudas">Deudas u obligaciones</label>
                                            <input type="number" id="deudas" name="deudas" placeholder="$1.000.000" className="form-control form-control-md"  value={cliente.deudas} onChange={handleInputChange} onBlur={handleFormatMoney} />
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="tipo_cuenta">Tipo cuenta bancaria</label>
                                            <select className="form-select form-control-md" name="tipo_cuenta" value={cliente.tipo_cuenta} onChange={handleInputChange} >
                                                <option value="AHORROS">AHORROS</option>
                                                <option value="CORRIENTE">CORRIENTE</option>
                                                <option value="CONVENIO">CONVENIO</option>
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-label" htmlFor="numero_cuenta"># Cuenta Bancaria</label>
                                            <input type="text" id="numero_cuenta" name="numero_cuenta" placeholder="00-00000-000" className="form-control form-control-md"  value={cliente.numero_cuenta} onChange={handleInputChange} />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="form-label" htmlFor="banco_id">Banco</label>
                                            <select className="form-select form-control-md" name="banco_id" value={cliente.banco_id} onChange={handleInputChange}>
                                                <option value="" disabled> -- Seleccnione un banco -- </option>
                                                {lista_bancos.map((banco, indice) => (
                                                    <option value={banco.id_banco} key={indice}>{banco.nombre}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className={class_form4}>
                                    <center><i className="bi bi-people text-secondary fs-1"></i></center>
                                    <h4 className='text-secondary text-center pb-4'>Referencias</h4>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="form-label" htmlFor="nombre">Nombres</label>
                                            <input type="text" id="nombre" name="nombre" placeholder="Nombres" className="form-control form-control-md"  value={referencia_cliente.nombre} onChange={handleInputReferenceChange} />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="form-label" htmlFor="apellido">Apellidos</label>
                                            <input type="text" id="apellido" name="apellido" placeholder="Apellidos" className="form-control form-control-md"  value={referencia_cliente.apellido} onChange={handleInputReferenceChange} />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="form-label" htmlFor="telefono">Telefono</label>
                                            <input type="number" id="telefono" name="telefono" placeholder="Telefono" className="form-control form-control-md"  value={referencia_cliente.telefono} onChange={handleInputReferenceChange} />
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="form-label" htmlFor="tipo">Tipo</label>
                                            <select className="form-select form-control-md" id='tipo' name="tipo" value={referencia_cliente.tipo} onChange={handleInputReferenceChange} >
                                                <option value="PERSONAL">PERSONAL</option>
                                                <option value="FAMILIAR">FAMILIAR</option>
                                                <option value="LABORAL">LABORAL</option>
                                            </select>
                                        </div>                                       
                                        <div className='col-md-3'>
                                            <br/>
                                            <button type="button" className="btn btn-primary mt-2" disabled={btn_add_reference} onClick={handleAddReference}>
                                                Agregar
                                            </button>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12 pt-4'>
                                            <table className='table table-hover table-list-references text-center'>
                                                <caption className={captionTableAddReference}>
                                                    <h5 className='text-secondary text-center'>No hay referencias agregadas</h5>
                                                </caption>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Nombres</th>
                                                        <th>Apellidos</th>
                                                        <th>Telefono</th>
                                                        <th>Tipo Referencia</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {list_reference}             
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className={class_form5}>
                                    <br></br>
                                    <center><i className="bi bi-houses text-secondary fs-1"></i></center>
                                    <h4 className='text-secondary text-center pb-4'>Activos del prospecto</h4>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <label className="form-label" htmlFor="tipo_activo_id">Tipo Activo</label>
                                            <select className="form-select form-control-md" name="tipo_activo_id" value={activo_cliente.tipo_activo_id} onChange={handleInputPropertiesChange} >
                                                <option>-- Seleccione --</option>
                                            {lista_tipo_activo.map(opcion => (
                                                <option key={opcion.id_tipo_activo} value={opcion.id_tipo_activo}>{opcion.nombre}</option>
                                            ))}
                                            </select>
                                        </div>
                                        <div className="col-md-5">
                                            <label className="form-label" htmlFor="descripcion">Activo</label>
                                            <input type="text" id="descripcion" name="descripcion" placeholder="Activo" className="form-control form-control-md"  value={activo_cliente.descripcion} onChange={handleInputPropertiesChange} />
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <label className="form-label" htmlFor="value">Valor</label>
                                            <input type="number" id="valor" name="valor" placeholder="$1.000.000" className="form-control form-control-md"  value={activo_cliente.valor} onChange={handleInputPropertiesChange} onBlur={handleFormatMoney} />
                                        </div>
                                        <div className="col-md-5">
                                            <label className="form-label" htmlFor="estado_activo">Estado del activo</label>
                                            <select className="form-select form-control-md" name="estado_activo" value={activo_cliente.estado_activo} onChange={handleInputPropertiesChange}>
                                                <option value="USADO">USADO</option>
                                                <option value="NUEVO">NUEVO</option>
                                                <option value="REGULAR">REGULAR</option>
                                                <option value="MALO">MALO</option>
                                                <option value="BUENO">BUENO</option>
                                                <option value="PERFECTO">PERFECTO</option>
                                                <option value="PENDIENTE">PENDIENTE</option>
                                            </select>
                                        </div>
                                        <div className='col-md-2'>
                                            <br/>
                                            <button type="button" className="btn btn-primary mt-2" disabled={btn_add_propertie} onClick={handleAddPropertie}>
                                                Agregar
                                            </button>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <table className='table table-hover table-list-propertie text-center'>
                                                <caption className={captionTableAddPropertie}>
                                                    <h5 className='text-secondary text-center'>No hay activos agregadas</h5>
                                                </caption>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Tipo</th>
                                                        <th>Activo</th>
                                                        <th>Valor</th>
                                                        <th>Estado</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {list_porpertie}             
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className={btn_atras} onClick={handleTabFormAtras}>Atras</button>
                            <button type="button" className={btn_siguiente} onClick={handleTabForm}>Siguiente</button>
                            <button type="button" className={btn_guardar} onClick={handleSaveClient}>Guardar Prospecto</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ModalNewClient