import React from 'react';
import logospace from "../assetss/img/logospace2.png"

function Footer() {
  return (
    <footer className="bg-ligth fixed-bottom text-dark p-1">
      <div className="container-fluid">
        <div className='text-center'>
            
        </div>
      </div>
    </footer>
  );
}

export default Footer;