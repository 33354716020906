import React,{ useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { editar_usuario } from "../../services/Login"
import { toast, cerrarModal, alerta } from "../../services/utilities";
import Swal  from 'sweetalert2';

function Editarusuario(props) {

    useEffect(() => {
        setForm({
            "id_usuario": props.usuario_editar.id_usuario,
            "nombre": props.usuario_editar.nombre??'',
            "email":props.usuario_editar.email??'',
            "celular":props.usuario_editar.celular??'',
            "estado_id": props.usuario_editar.estado_id,
            "perfil_id": props.usuario_editar.perfil_id,
            "password":"",
            "password_dos":""
        });
    }, [props.usuario_editar.id_usuario]);

    const [spiner, setSpiner] = useState(false);
    const [form, setForm] = useState({});

    const handleInputChange = (event) => {
        const { name, value, type } = event.target;
        setForm({ ...form, [name]: value });    
    };

    const handleConfirmarEditar = () => {

        if(form.nombre == '' || form.nombre.length < 4){
            toast('* Campos requeridos','Debe ingresar un nombre valido, mínimo 4 caracteres', true);
            return;
        }

        if(form.password != '' || form.password_dos !=''){

            if(form.password == '' || form.password.length < 4){
                toast('* Campos requeridos','La contraseña debe tener mínimo 4 caracteres', true);
                return;
            }
    
            if(form.password !== form.password_dos ){
                toast('* Campos requeridos','Las contraseñas deben coincidir.', true);
                return;
            }
        }

        Swal.fire({
            html: `
                    <p><b>Por favor, verifique la información del usuario.</b></p>
                    <hr></hr>
                    <div class='d-flex align-items-center justify-content-between  m-0'>
                        <h6 class="card-subtitle mb-2 text-body-secondary">Nombre del usuario</h6>
                        <p class='m-0'><b>${form.nombre}</b></p>
                    </div>
                    <div class='d-flex align-items-center justify-content-between  m-0'>
                        <h6 class="card-subtitle mb-2 text-body-secondary">Usuario para inicio de sesion.</h6>
                        <p class='m-0'><b>${props.usuario_editar.nickname}</b></p>
                    </div>                   
                    <hr>
                    <p class="text-secondary" >Si está seguro, presione el botón 'Editar'. De lo contrario, presione 'Cancelar'.</p>
            `,
            showCancelButton: true,
            confirmButtonColor: '#198754',
            cancelButtonColor: '#6c757d',
            confirmButtonText: 'Editar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                handleEditarUsuario()
            }
        });
    }

    const handleEditarUsuario = async () => {

        setSpiner(true);

        await editar_usuario(form).then(response => {

            if(response.status === 200 && response.data.status_message == 'OK'){

                cerrarModal('modalEditarUsuario');
        
                Swal.fire({
                    title: 'Usuario editado de forma exitosa ('+response.data.id_usuario+')',
                    text:  'Recuerde que el usuario se puede editar desde el modulo de usuarios.',
                    icon: "success",
                    confirmButtonColor: '#198754',
                    confirmButtonText: 'Aceptar',
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.href = '/Inicio';
                    }
                });

            }else{
                alerta(response.data.status_message, response.data.error_message)
            }
        }).catch(error =>{

            Swal.fire({
                title: 'Error no controlado al editar el usuario',
                text:  error.message+' ES POSIBLE QUE EL USUARIO SE HAYA EDITADO, POR FAVOR CONFIRME EN EL MODULO DE USUARIOS.',
                icon: "error",
                confirmButtonColor: '#dc3545',
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = '/Inicio';
                }
            });
        })

        setSpiner(false);
    }

    return(

        <React.Fragment>
            <div className="modal fade" id="modalEditarUsuario" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                    <div className="modal-content">
                        <div className="modal-header bg-header-modal">
                        <h5 className="m-0">Editar usuario</h5>                         
                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id="nombre" name="nombre" value={form.nombre}  onChange={handleInputChange}  />
                                        <label htmlFor="nombre">Nombre complero <span className='text-danger'>*</span></label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id="nickname" name="nickname" value={props.usuario_editar.nickname}  disabled />
                                        <label htmlFor="nickname">nickname (usuario inicio sesion)</label>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-md-6'>
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id="email" name="email" value={form.email}  onChange={handleInputChange}  />
                                        <label htmlFor="email">Correo</label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id="celular" name="celular" value={form.celular}  onChange={handleInputChange}  />
                                        <label htmlFor="celular">Telefono</label>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-md-6'>
                                    <div className="form-floating">
                                        <select className="form-select" id="perfil_id" name="perfil_id"  value={form.perfil_id}  disabled={true}>
                                            <option value="3">Auxiliar</option>
                                        </select>
                                        <label htmlFor="perfil_id">Perfil</label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-floating">
                                        <select className="form-select" id="estado_id" name="estado_id"  value={form.estado_id} onChange={handleInputChange}>
                                            <option value="1">Activo</option>
                                            <option value="0">Inactivo</option>
                                        </select>
                                        <label htmlFor="perfil_id">Estado</label>
                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                            <h5 className='mt-2'>Cambiar contraseña</h5>
                            <div className='row mt-4'>
                                <div className='col-md-6'>
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id="password" name="password" value={form.password}  onChange={handleInputChange}  />
                                        <label htmlFor="password">Contraseña</label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id="password_dos" name="password_dos" value={form.password_dos}  onChange={handleInputChange}  />
                                        <label htmlFor="password_dos">Confirmar contraseña</label>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button 
                                className="btn btn-secondary btn-lg" 
                                data-bs-dismiss="modal"
                                >Cancelar
                            </button>   
                            <button 
                                className="btn btn-success btn-lg"  
                                onClick={handleConfirmarEditar}                              
                                >Editar usuario
                            </button>                        
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapSateToProps = state => {

    return {
        usuario_editar: state.usuario_editar
    }
}

export default connect(mapSateToProps, null)(Editarusuario)