import axios from 'axios'

const API_URL = "https://www.apifinan.site/";

const instance = axios.create({
    withCredentials: false,
    baseURL: API_URL,
    headers: {
        'Authorization': localStorage.getItem("token"),
        'Content-Type': 'multipart/form-data'
    }
})

export default instance