import React,{ useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { get_usuarios } from "../../services/Login"
import { setUsuarioEditar } from '../../actions'
import { alerta } from "../../services/utilities";

function AjustedeUsuario(props) {

    useEffect(() => {
        setSpiner(true);
        handleConsultarUsuarios()
        setNoFoundCredito(false)
    }, [props.idCredito]);

    const [spiner, setSpiner] = useState(true);
    const [hayData, setHayData] = useState(false);
    const [noFoundCredito, setNoFoundCredito] = useState(false);
    const [data, setData] = useState({});
    
    const handleConsultarUsuarios = async () => {

        setSpiner(true);

        try {
            await get_usuarios().then(response => {
                if(response.status === 200 && response.data.status_message == 'OK'){
                    setHayData(true);
                    setData(response.data.data);
                }else{
                    setNoFoundCredito(true)
                }
            });
         }catch (error) {
            const errorMessage = error.message || 'Error desconocido';
            alerta('Error al cargar la información de los usuarios', `Por favor intente nuevamente. Detalles del error: ${errorMessage}`);
        }

        setSpiner(false);
    }

    return(

        <React.Fragment>
            <div className="modal fade" id="modalAjustedeUsuario" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                    <div className="modal-content">
                        <div className="modal-header bg-header-modal">
                        <h5 className="m-0">Usuarios</h5>                         
                        </div>
                        <div className="modal-body">
                        {spiner ?
                            <div className="d-flex align-items-center mb-3">
                                <strong role="status" className='text-secondary'>Cargando Informacion...</strong>
                                <div className="spinner-border ms-auto text-secondary" aria-hidden="true"></div>
                            </div>
                            :''}
                            { hayData ?
                                <>
                                    <div className='scroll-tabla'>
                                        <div className="table-responsive">
                                            <table className="table table-hover table-sm text-center" style={{fontSize:'12px'}}>
                                                <thead>
                                                    <tr>
                                                        <th>Nombre</th>
                                                        <th>Nickname</th>
                                                        <th>email</th>
                                                        <th>telefono</th>
                                                        <th>Perfil</th>
                                                        <th>Estado</th>                                                        
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {Array.isArray(data) ? data.map((item, index) => (
                                                    <tr key={index} className='align-middle'>
                                                        <td>
                                                            {item.nombre}
                                                        </td>
                                                        <td>
                                                            {item.nickname}
                                                        </td>
                                                        <td>
                                                            {item.email}
                                                        </td>  
                                                        <td>
                                                            {item.celular}
                                                        </td>
                                                        <td>
                                                            {item.perfil}
                                                        </td>                                                         
                                                        <td>
                                                        {item.estado_id === 1 ?
                                                            <span className="badge text-bg-success">{item.estado}</span>
                                                            : 
                                                            <span className="badge text-bg-danger">{item.estado}</span>
                                                        }
                                                        </td>                                                       
                                                        <td>
                                                            <button 
                                                                type="button" 
                                                                className="btn btn-outline-primary btn-sm"
                                                                data-bs-target="#modalEditarUsuario" 
                                                                data-bs-toggle="modal"
                                                                onClick={() =>{
                                                                    props.setUsuarioEditar(item);;
                                                                }}
                                                            >
                                                            <i className="bi bi-person-gear"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )) : <tr></tr>}
                                                </tbody>
                                            </table>
                                        </div>  
                                    </div>   
                                </>
                            :''}
                            {noFoundCredito ?
                            <div className="d-flex flex-column justify-content-center align-items-center text-center text-secondary my-4" style={{height: '30dvh'}}>
                                <i className="bi bi-search fs-1"></i>
                                <br></br>
                                <h3>No se encontraron usuarios</h3>
                                <br></br>
                                <p className='text-success'><b>Por favor, recargue la pagina e intente nuevamente.</b></p>
                            </div>:''}
                        </div>
                        <div className="modal-footer">
                            <button 
                                className="btn btn-secondary btn-lg" 
                                data-bs-dismiss="modal"
                                >Cancelar
                            </button>                           
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapDispatchToProps  = {
    setUsuarioEditar
};

export default connect(null, mapDispatchToProps)(AjustedeUsuario)