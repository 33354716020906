import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { reducer } from './reducers/index'
import './assetss/css/index.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import App from './App';

// Se crea un elemento de la propiedad ReacDOMM obteniendo el contenedor donde se va renderizar 
// toda la aplicacion, en este caso el <div id="root"> que esta en el archivo index.html de la 
// carpeta public.
const root = ReactDOM.createRoot(document.getElementById('root'));

// Se ejecuta la funcion render del objetro creado anteriormente y se le pasada <React.StrictMode> que es una
// Etiqueta propia de REACT pero podria ser un <div> o cualquier contendero y dentro se pasa el componente <APP />
// que contiene nuestros componentes principales o inicio de la aplicacion



const initialState = {
  "usuario":{

  },
  "usuario_editar":{
    "id_usuario": 0,
    "nickname": "",
    "nombre": "",
    "email": null,
    "celular": null,
    "ultimo_ingreso": null,
    "fecha_creacion": "",
    "tipo_usuario": "EMPRESA",
    "estado_id": 1,
    "perfil_id": 2
  },
  "idCredito":0,
  "dataClientes": [],
  "saldosEmpresa": {
    "disponible":0,
    "prestado":0,
    "intereses":0
  },
  "credito":{
    "fecha_inicio" :"",
    "fecha_ultimo_pago":"",
    "fecha_fin" :"",
    "observacion":"",
    "cantidad_cuotas": 0,
    "interes" : 0,
    "cuota_mensual": 0,
    "pago_total_interes": 0,
    "pago_total_credito": 0,
    "tipo_credito": "",
    "valor": 0,
    "saldo_actual":"",
    "credito_nuevo":"1",
    "numero_cuota_ajuste":"",
    "cliente_id": null,
    "tipo_garantia": "",
    "credito_item": false,
    "vehiculos": [],
    "propiedades": [],
    "letras": [],
},
  "cliente":{
    "nombre":""
  }
}

const store = createStore(reducer, initialState)

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);


